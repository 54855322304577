import { createStore } from 'vuex'

export default createStore({
  state: {
    isLoading: false,
    isAuthenticated: false,
    token: '',
    footer:false
  },
  getters: {
  },
  mutations: {
    initializeStore(state) {
      if (localStorage.getItem('token')) {
          state.token = localStorage.getItem('token')
          state.isAuthenticated = true
      } else {
          state.token = ''
          state.isAuthenticated = false
      } 
    },
    setIsLoading(state, status) {
      state.isLoading = status
    },
    setToken(state, token) {
      state.token = token
      state.isAuthenticated = true
    },  
    removeToken(state) {
      state.token = ''
      state.isAuthenticated = false
    }
  },
  actions: {
    parallax() {
      let poster;
      let layers;
      let width, height;

      width = window.innerWidth;
      height = window.innerHeight;
      poster = document.getElementsByClassName('parallax1')[0];
      layers = document.querySelectorAll('[data-offset]');

      document.addEventListener('mousemove', event => {
        const {pageX, pageY} = event;
        const offsetX = 0.5 - pageX / width;
        const offsetY = 0.5 - pageY / height;
        const amount = 5;
        poster.style.transform = `
          translateY(${-offsetY * amount}px)
          rotateX(${-offsetY * amount}deg)
          rotateY(${-offsetX * amount * 2}deg)
        `;

        layers.forEach(el => {
          const attr = el.getAttribute('data-offset');
          const v = parseInt(attr);
          el.style.transform = `
          translateX(${offsetX * v}px)
          translateY(${offsetY * v}px)
          `;
        });
        
        const dx = pageX - width / 2;
        const dy = pageY - height / 2;
        const theta = Math.atan2(dy, dx);
        const angle = theta * 180 / Math.PI;
      })
    }
  },
  modules: {
  }
})
