<template>
    <div class="contacto-view">
        <div id="section-1">
            <div class="parallax1">
                <transition name="slide-left">
                    <div id="titulo-1" v-if="showTitulo1" class="layer-2" data-offset="-50">
                        <p>Contacto</p>
                    </div>
                </transition>
            </div>
        </div>

        <div id="section-2-contacto">
            <div id="inner-contacto-view">
                <transition name="slide-fade1" class="shadow-lg">
                    <div id="form-contacto-div" v-if="show">
                        <div class="relative z-0" id="nombre-contacto">
                            <input type="text" v-model="contacto.nombre" id="floating_standard" class="block py-2.5 px-0 w-50 text-sm text-black bg-transparent border-0 border-b-2 border-gray-500 appearance-none dark:text-black dark:border-black-600 dark:focus:border-black focus:outline-none focus:ring-0 focus:border-black peer" placeholder=" " />
                            <label for="floating_standard" class="absolute text-sm text-black dark:text-black duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-black peer-focus:dark:text-grey-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Nombre</label>
                            <p v-if="errorNombre"  class="mt-1 text-xs text-red-600 dark:text-red-500 absolute">{{ errorNombre }}</p>
                        </div>

                        <div class="relative z-0">
                            <input type="number" v-model="contacto.telefono" id="floating_standard" class="block py-2.5 px-0 w-50 text-sm text-black bg-transparent border-0 border-b-2 border-gray-500 appearance-none dark:text-black dark:border-black-600 dark:focus:border-black focus:outline-none focus:ring-0 focus:border-black peer" placeholder=" " />
                            <label for="floating_standard" class="absolute text-sm text-black dark:text-black duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-black peer-focus:dark:text-grey-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Telefono</label>
                            <p v-if="errorTelefono" class="mt-1 text-xs text-red-600 dark:text-red-500 absolute">{{ errorTelefono }}</p>
                        </div>

                        <div class="relative z-0">
                            <input type="email" v-model="contacto.email" id="floating_standard" class="block py-2.5 px-0 w-50 text-sm text-black bg-transparent border-0 border-b-2 border-gray-500 appearance-none dark:text-black dark:border-black-600 dark:focus:border-black focus:outline-none focus:ring-0 focus:border-black peer" placeholder=" " />
                            <label for="floating_standard" class="absolute text-sm text-black dark:text-black duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-black peer-focus:dark:text-grey-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">E-mail</label>
                            <p v-if="errorEmail" class="mt-1 text-xs text-red-600 dark:text-red-500 absolute">{{ errorEmail }}</p>
                        </div>

                        <div id="comentario-div">                   
                            <label for="message" class="block mb-2 text-sm font-medium text-gray-900 dark:text-black">Comentarios</label>
                            <textarea id="message" v-model="contacto.comentarios" rows="2" cols="18" class="block p-2.5 text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-black dark:focus:border-black" placeholder="Consultas o sugerencias..."></textarea>
                            <p v-if="errorComentarios" class="mt-1 text-xs text-red-600 dark:text-red-500 absolute">{{ errorComentarios }}</p>
                        </div>

                        <div class="is-loading-bar sound-wave" v-if="this.$store.state.isLoading">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
        
                        <div class="text-center"> 
                            <button type="button" id="boton-enviar-contacto" class="text-white bg-black rounded py-2.5 px-5 text-[15px] mb-5 lg:hover:translate-y-[-0.25em] lg:hover:shadow-button transition duration-500" data-token="" @click="getRecaptcha">
                                Enviar
                            </button>
                        </div>
                    </div>
                </transition>

                <div id="info-map-contacto-div">
                    <transition name="slide-fade2" class="shadow-lg">
                        <div id="info-contacto" v-if="show">
                            <p>Estrada 5253 <br> Mar del Plata <br> Buenos Aires <br> Argentina</p>
                            <p>+54 2235906991</p>

                            <div class="redes-div-contacto">
                                <div>
                                    <a href="https://www.instagram.com/labarbieriamdp/" target="_blank"><img class="lg:hover:translate-y-[-0.25em] lg:hover:shadow-button transition duration-500" src="@/assets/images/insta.png" alt=""></a>
                                </div>
                        
                                <div>
                                    <a href="https://www.facebook.com/labarbieriamdp/" target="_blank"><img class="lg:hover:translate-y-[-0.25em] lg:hover:shadow-button transition duration-500" src="@/assets/images/face.png" alt=""></a>
                                </div>
                        
                                <div>
                                    <img class="lg:hover:translate-y-[-0.25em] lg:hover:shadow-button transition duration-500" src="@/assets/images/whatsapp.png" alt="" @click="mandarWhatsApp">
                                </div>
                            </div>
                        </div>
                    </transition>

                    <transition name="slide-fade3" class="shadow-lg">
                        <div id="map-contacto" v-if="show">
                            <GoogleMap id="g-map" api-key="AIzaSyB5SJblOVmnXUaOc91smQIfStdPMK--2iw" :center="center" :zoom="14">
                                <CustomMarker :options="{ position: center, anchorPoint: 'BOTTOM_CENTER' }">
                                <div>
                                    <img src="@/assets/images/logo-barbieria.png" width="150" height="150" style="margin-top: 8px" />
                                </div>
                                </CustomMarker>
                                
                            </GoogleMap>  
                        </div>
                    </transition>
                </div>
            </div>
        </div>

        <div id="map-movil">
            <transition name="slide-fade3" class="shadow-lg">
                <div id="map-contacto" v-if="show">
                    <GoogleMap id="g-map-movil" api-key="AIzaSyB5SJblOVmnXUaOc91smQIfStdPMK--2iw" :center="center" :zoom="14">
                        <CustomMarker :options="{ position: center, anchorPoint: 'BOTTOM_CENTER' }">
                        <div>
                            <img src="@/assets/images/logo-barbieria.png" width="150" height="150" style="margin-top: 8px" />
                        </div>
                        </CustomMarker>
                        <CustomMarker :options="{ position: center }">
                            <InfoWindow>
                                <div id="contet">
                                <div id="siteNotice"></div>
                                <h1 id="firstHeading" class="firstHeading">La Barbieria</h1>
                                <div id="bodyContent">
                                    <p>
                                    Academia y Barberia <br>
                                    Estrada 5253, Mar del Plata, 
                                    Provincia de Buenos Aires, 
                                    Argentina
                                    </p>
                                
                                </div>
                                </div>
                            </InfoWindow>
                        </CustomMarker>
                    </GoogleMap>  
                </div>
            </transition>
        </div>
        <Transition name="slide-fade">
            <ToastComp 
                v-if="toast"
                :mensaje="mensaje"
                :tipo="tipo"
            />
        </Transition>
    </div>
 </template>
   
<script>
    import { GoogleMap, CustomMarker, InfoWindow} from "vue3-google-map";
    import ToastComp from '@/components/ToastComp.vue'
    import axios from 'axios'

    export default {
        name: 'ContactoView',
        data() {
        return {
            center: { lat: -37.95459348426065, lng: -57.54831527561181 },
            contacto: {
                nombre:'',
                telefono:'',
                email:'',
                comentarios:'',
            },
            errorNombre:'',
            errorTelefono:'',
            errorEmail:'',
            errorComentarios:'',
            toast:false,
            mensaje:'',
            tipo:'',
            show:false,
            showTitulo1:false,
        }
        },
        mounted() {
            document.title = 'Contacto | Academia La Barbieria'
            setTimeout(() => {
                this.showTitulo1 = true;
            }, 200);
            setTimeout(() => {
                this.show = true
            }, 800);
            setTimeout(() => {
                this.$store.state.footer = true;
            }, 2000);
            //this.$store.dispatch('parallax')
        },
        components: {
            GoogleMap, 
            CustomMarker,
            InfoWindow,
            ToastComp
        },
        methods: {
            async getRecaptcha(e) {
                if (!this.contacto.nombre) {
                    this.errorNombre = 'Requerido'
                    setTimeout(() => this.errorNombre = '', 2000)
                }
                if (!this.contacto.email) {
                    this.errorEmail = 'Requerido'
                    setTimeout(() => this.errorEmail = '', 2000)
                }
                if (!this.contacto.telefono) {
                    this.errorTelefono = 'Requerido'
                    setTimeout(() => this.errorTelefono = '', 2000)
                }
                if (!this.contacto.comentarios) {
                    this.errorComentarios = 'Requerido'
                    setTimeout(() => this.errorComentarios = '', 2000)
                }
                
                e.preventDefault();

                if (!this.errorNombre && !this.errorEmail && 
                    !this.errorTelefono && !this.errorComentarios) {

                    const boton = document.getElementById('boton-enviar-contacto');
                    grecaptcha.ready(function() {
                        grecaptcha.execute('6Le7L8kmAAAAALZTlKhFj2CJXwZ1nxCXy506leyn')
                        .then(response => {
                            // Add your logic to submit to your backend server here.
                            boton.dataset.token = response;
                        })
                    });

                    setTimeout(() => {
                        this.contactar(e)
                    }, 2000);
                }
            },
            async mandarWhatsApp (e) {
                let a= document.createElement('a');
                a.target= '_blank';
                a.href= "https://wa.me/5492235748516/?text=Hola, estoy interesado en los cursos, me podrias dar mas info por favor? Gracias!"
                a.click();
            },
            async geolocate() {
                navigator.geolocation.getCurrentPosition(position => {
                    this.center = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    };
                });
            },
            async contactar(e) {
                this.$store.commit('setIsLoading', true)
                
                const data = {
                    cliente:this.contacto,
                    token:e.target.dataset.token
                }

                await axios
                    .post('/api/v1/contactar/', data)
                    .then(response => {
                        if (response.data == 'enviado') {
                            this.mensaje = 'Mensaje enviado con exito.\nA la brevedad nos comunicaremos.\nGracias!'
                            this.tipo = 'success'
                            this.toast =true
                            setTimeout(() => this.toast = false, 3000) 
                        } 
                        if (response.data == 'robot') {
                            this.mensaje = 'Na na na robot'
                            this.tipo = 'error'
                            this.toast =true
                            setTimeout(() => this.toast = false, 3000)
                        }
                        this.contacto = {
                            nombre:'',
                            email:'',
                            telefono:'',
                            comentarios:''
                        }
                    })
                    .catch(error => {
                        this.mensaje = 'Mensaje no enviado.\nIntentalo nuevamente.\nGracias!'
                        this.tipo = 'error'
                        this.toast =true
                        setTimeout(() => this.toast = false, 3000)
                    })
                    this.$store.commit('setIsLoading', false)  
            },
        }
    }
</script>
   
   
<style>
    .contacto-view {
        padding-top: 110px;
        text-align: center;
        margin-bottom: 100px;
        min-height: 870px;
    }

    #section-2-contacto {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 0px 50px;
    }

    #inner-contacto-view {
        display: flex;
        align-items: center;
        width: 90%;
        position: relative;
    }

    #titulo-1 {
        background-color: #111111;
        width: fit-content;
        box-shadow: var(--shadow2-1) 5px 5px, var(--shadow2-2) 10px 10px, var(--shadow2-3) 15px 15px, var(--shadow2-4) 20px 20px, var(--shadow2-5) 25px 25px;
    }

    #titulo-1 p {
        font-size: 55px;
        font-family: 'Poppins', sans-serif;
        text-transform: uppercase;
        color: whitesmoke;
        padding: 10px 20px;
        transition: 1s;
    }

    #titulo-2 {
        background-color: #111111;
        width: fit-content;
        box-shadow: var(--shadow-1) 5px 5px, var(--shadow-2) 10px 10px, var(--shadow-3) 15px 15px, var(--shadow-4) 20px 20px, var(--shadow-5) 25px 25px;
    }

    #titulo-2 p {
        font-size: 40px;
        font-family: 'Poppins', sans-serif;
        text-transform: uppercase;
        color: whitesmoke;
        padding: 10px 20px;
        transition: 1s;
        text-align: left;
    }

    #form-contacto-div {
        width: 400px;
        text-align: left;
        background-color: whitesmoke;
        box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
    }

    #nombre-contacto {
        margin-top: 40px;
    }

    .z-0, #comentario-div {
        margin: 0 auto 30px auto;
        width: fit-content;
    }

    #g-map {
        width: 650px;
        height: 487px;
        transition: 1s;
        box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
    }

    #map-movil {
        display: none;
    }

    #form-contacto-div {
        margin-right: 100px;
        transition: 1s;
    }

    #info-map-contacto-div {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        margin-left:  100px;
    }

    #map-contacto {
        margin-left: -20px;
    }

    #info-contacto {
        background-color: rgb(194, 194, 194);
        padding: 60px;
        box-shadow: rgba(0, 0, 0, 0.322) 2.4px 2.4px 3.2px;
    }

    #info-contacto p {
        font-size: 25px;
        text-align: left;
        transition: 1s;
    }

    .redes-div-contacto div {
        display: inline-table;
        padding: 10px;
    }

    .redes-div-contacto div img {
        width: 50px;
        cursor: pointer;
    }

    #toast-div {
        top: 15%;
    }

    .slide-fade0-enter-active,
    .slide-fade1-enter-active,
    .slide-fade2-enter-active,
    .slide-fade3-enter-active {
        transition: all 0.5s ease-out;
    }

    .slide-fade0-enter-from,
    .slide-fade0-leave-to {
        transform: translateY(-70px);
        opacity: 0;
    }

    .slide-fade1-enter-from,
    .slide-fade1-leave-to {
        transform: translateX(-100px);
        opacity: 0;
    }

    .slide-fade2-enter-from,
    .slide-fade2-leave-to {
        transform: translateY(100px);
        opacity: 0;
    }

    .slide-fade3-enter-from,
    .slide-fade3-leave-to {
        transform: translateX(70px);
        opacity: 0;
    }

    .slide-fade-enter-active {
        transition: all 0.3s ease-out;
    }

    .slide-fade-leave-active {
        transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
    }

    .slide-fade-enter-from,
    .slide-fade-leave-to {
        transform: translateX(20px);
        opacity: 0;
    }

    @media only screen and (max-width: 1670px) {
        #g-map {
            width: 500px;
        }

        #form-contacto-div {
            margin-right: 50px;
        }

        #info-map-contacto-div {
            margin-left: 50px;
        }
    }   
    
    @media only screen and (max-width: 1600px) {
        #g-map {
            width: 400px;
        }
    } 

    @media only screen and (max-width: 1500px) {
        #g-map {
            width: 400px;
        }

        #form-contacto-div {
            margin-right: 10px;
        }

        #info-map-contacto-div {
            margin-left: 10px;
        }
    } 

    @media only screen and (max-width: 1400px) {
        #g-map {
            width: 350px;
        }   
    } 

    @media only screen and (max-width: 1350px) {
        .contacto-view #section-1 {
            padding: 40px 10px;
        }

        #section-2-contacto {
            padding: 0px 10px;
        }
        #map-contacto {
            margin-left: -5px;
        }

        #info-contacto p {
            font-size: 20px;
        }

        #info-contacto {
            padding: 30px;
        }

        .redes-div-contacto div img  {
            width: 40px;
        }
    }

    @media only screen and (max-width: 1140px) {
        #form-contacto-div,
        #g-map {
            width: 320px;
        }

        #info-contacto, #map-contacto {
            margin-left: 0px;
            margin-right: -10px;
        }

        #info-contacto {
            padding: 20px;
        }

        #info-contacto p {
            font-size: 17px;
        }

        #info-map-contacto-div {
            margin-left: 20px;
        }

        .redes-div-contacto div img  {
            width: 40px;
        }
    }

    @media only screen and (max-width: 1000px) {
        .contacto-view {
            margin-bottom: 0px;
            min-height: 0;
        }

        #titulo-1 p {
            font-size: 45px;
        }

        #titulo-2 p {
            font-size: 33px;
        }

        #section-2-contacto {
            display: block;
            padding: 0px;
            margin-right: 30px;
            margin-left: 30px;
        }

        #form-contacto-div {
            width: 320px;
        }

        #map-contacto {
            display: block;
            margin-top: 50px;
        }

        #g-map {
            display: none;
        }

        #map-movil {
            display: block;
        }

        #g-map-movil {
            width: 100%;
            height: 200px;
        }

        #info-contacto {
            width: 320px;
        }

        #info-contacto p {
            text-align: center;
        }

        #info-contacto, #map-contacto {
            margin-left: 0;
            margin-right: 0;
        }
        
        #form-contacto-div,
        #info-contacto {
            display: inline-table;
        }

        #inner-contacto-view,
        #info-map-contacto-div {
            display: inline;
        }

        .slide-fade3-enter-from,
        .slide-fade3-leave-to {
            transform: translateX(0px);
            opacity: 0;
        }
    }
    
    @media only screen and (max-width: 900px) {
        #titulo-1 p {
            font-size: 34px;
        }
        #titulo-2 p {
            font-size: 26px;
        }
    }
   
    @media only screen and (max-width: 740px) {
        #form-contacto-div {
            margin-bottom: 40px;
            margin-right: 0;
        }

        #map-contacto {
            margin-top: 20px;
        }

        #info-map-contacto-div {
            margin-left: 0;
            width: 100%;
        }
        
        #info-contacto p {
            font-size: 18px;
            text-align: center;
        }

        #inner-contacto-view {
            display: flex;
            flex-direction: column;
            padding: 0;
            width: 100%;
        }
   
        .redes-div-contacto div img {
            width: 30px;
            cursor: pointer;
        }
     }

     @media screen and (max-width:500px) {
        #titulo-1 p {
            font-size: 28px;
        }
        #titulo-2 p {
            font-size: 20px;
        }

        .contacto-view #section-1 {
            margin: 0 auto 40px auto;
            padding: 20px 30px 0px 10px;
        }

        #form-contacto-div,
        #info-contacto {
            width: 300px;
            margin: 0;
        }

        #info-map-contacto-div {
            margin: 30px auto 0 auto;
        }
    }

     @media only screen and (max-width: 400px) {
        #form-contacto-div,
        #info-contacto {
            width: 100%;
            margin: 0;
        }

        #info-map-contacto-div {
            margin: 30px auto 0 auto;
        }
     }

     @media only screen and (max-width: 300px) {
        #form-contacto-div,
        #info-contacto {
            width: 230px;
            margin: 0;
        }
     }

   </style>