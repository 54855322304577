<template>
  <div class="home-view">
    <swiper 
    effect='fade'
    :loop="true"
    :autoplay="{
      delay: 15000,
      disableOnInteraction: false,
    }"
    :pagination="{
      clickable: true,
      dynamicBullets: true,
    }"
    :modules="modules"
    class="mySwiper">
      <swiper-slide v-for="video in videos" :key="video">
        <div class="video-web-div">
          <video autoplay :muted="mute" loop>
            <source id="video" :src="require('@/assets/videos/' + video.web)" type="video/mp4">
            <track src="" kind="" srclang="" label="No sound">
          </video>
        </div>

        <div class="video-movil-div">
          <video autoplay :muted="mute" loop>
            <source id="video" :src="require('@/assets/videos/' + video.movil)" type="video/mp4">
          </video>
        </div>
        <div class="overlay"></div>
      </swiper-slide>
    </swiper>
    
    <div id="section-1">
      <div class="parallax1">
          <div class="layer-2">
            <transition name="slide-left">
              <div id="la-barbieria-div" v-if="showTitulo">
                <p id="la-barbieria">La Barbieria Academia y Peluquería</p>
              </div>
            </transition>
          </div>
          
          <div >
            <transition name="slide-left">
              <p id="descripcion" v-if="showDescripcion">En nuestra academia, nos apasiona el arte de la peluquería y barbería, 
                por eso estamos comprometidos a brindarte la mejor formación para que te conviertas 
                en un profesional exitoso en este fascinante campo. Si soñas con crear peinados 
                increíbles, cortes de moda y barbas impecables, has llegado al lugar adecuado.
              </p>
            </transition>
          </div>
      </div>
    </div>

    <div id="section-2" v-if="showSection2">
      <swiper
        :autoplay="{
          delay: 3000,
          disableOnInteraction: false,
        }"
        :modules="modules"
        :freeMode="true"
        class="mySwiper2"
        :breakpoints="{
          '0': {
            slidesPerView: 1,
            spaceBetween: 0,
            loop: true
          },
          '400': {
          slidesPerView: 2,
          spaceBetween: 0,
          loop: true
          },
          '700': {
            slidesPerView: 3,
            spaceBetween: 0,
            loop: true
          },
          '1024': {
            slidesPerView: 4,
            spaceBetween: 0,
          },
          '1400': {
            slidesPerView: 5,
            spaceBetween: 0,
          },
          '1900': {
            slidesPerView: 6,
            spaceBetween: 5,
            
          },
        }"
      >
        <swiper-slide v-for="image in images" :key="image">
          <div id="cuadro">
            <img :src="require(`@/assets/images/${image}`)" alt="">
          </div>
        </swiper-slide>
      </swiper>
    </div>

    <div id="section-3">
      <div id="inner-3-div" class="parallax1">
        <div id="descripcion-3-div" class="layer-2">
          <p id="descripcion-3" v-if="showSection3">
            Nuestras instalaciones están equipadas con las últimas herramientas y tecnologías, 
            brindándote un entorno de aprendizaje moderno y profesional. 
            Además, trabajamos con productos de alta calidad, para que puedas familiarizarte 
            con las mejores marcas y desarrollar una comprensión profunda de los productos 
            adecuados para cada cliente.
          </p>
        </div>

        <div id="imagen-div" v-if="showSection3">
          <div class="box">
              <img src="@/assets/images/herramientas-labarbieria.jpeg" alt="">
          </div>
        </div>
      </div>
    </div>

    <div id="imagen-movil-1" v-if="showImgMovil"></div>

    <div id="section-4" v-if="showSection4">
      <div id="inner">
        <div id="titulo-4-div" class="layer-2" data-offset="10">
          <p>
            Estudia con nosotros y asegura tu futuro! <br>
            Descubrí el curso indicado para vos.
          </p>
        </div>
        </div>
        <div id="boton-div">
            <div class="content">
              <router-link to="/cursos" @click="refreshFooter">
                <div class="content-overlay"></div>
                <img class="content-image" src="@/assets/images/unas-labarbieria.jpeg" alt="">
                <div class="content-details">
                  <h3 class="content-title">Cursos</h3>
                </div>
              </router-link>
            </div>
        </div>
    </div>
  </div>
</template>


<script>
// @ is an alias to /src

import { Swiper, SwiperSlide} from "swiper/vue";
import { EffectFade, Parallax } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import 'swiper/css/free-mode';
import { Autoplay, Pagination, Navigation, FreeMode } from "swiper";

export default {
  name: 'HomeView',
  emits: ['parallax'],
  data() {
    return {
      mute:true,
      modules: [Autoplay, Navigation, EffectFade, Pagination, FreeMode],
      center: { lat: -37.954643, lng: -57.548370 },
      videos: [
        {
          web:"video-1-web.mp4",
          movil:"video-1-movil.mp4"
        },
        {
          web:"video-3-web.mp4",
          movil:"video-3-movil.mp4"
        }
      ],
      images:['foto-3.jpeg','foto-4.jpeg','foto-2.jpeg', 
              'foto-5.jpeg', 'foto-6.jpeg', 'foto-7.jpeg',
              'foto-5.jpeg', 'foto-6.jpeg', 'foto-7.jpeg'],
      imgPerView:7,
      show:false,
      showTitulo:false,
      showDescripcion:false,
      showSection2:false,
      showSection3:false,
      showSection4:false,
      showImgMovil:false
    }
  },
  components: {
    Swiper,
    SwiperSlide
  },
  mounted() {
    document.title = 'Inicio | Academia La Barbieria'
    /* this.getResolution(); */
    setTimeout(() => {
      this.showTitulo = true;
    }, 200);
    setTimeout(() => {
      this.showDescripcion = true;
      this.showSection2 = true; 
      this.showSection3 = true;
      this.showSection4 = true;
      this.showImgMovil = true;
      this.horizontalMovement()
    }, 1000);
    setTimeout(() => {
        this.$store.state.footer = true;
    }, 2000);
  },
  methods: {
    getResolution() {
      var w = window.innerWidth;
      if (parseInt(w) > 1000) {
        this.$store.dispatch('parallax')
      }
    },
    refreshFooter(e) {
      this.$store.state.footer = false;
    },   
    horizontalMovement() {
      let section3 = document.querySelector("#inner-3-div");
      let section3Initial = section3.getBoundingClientRect().x;

      window.addEventListener("scroll", function(e) {
        let h= $(document).height();
        let current = window.scrollY;
        let per = (current / h)

        section3.style.left = -(section3Initial * per * 3) + section3Initial +  "px";
        if (-(section3Initial * per * 3) + section3Initial < 0) {
          section3.style.left = '0px'
        }
      });
    }
  }
}
</script>


<style lang="scss">
/* Home section */

  .home-view {
      width: 100%;
      margin-bottom: 0px;
  }

  .video-movil-div {
    display: none;
  }

  .mySwiper {
    max-height: 700px;
  }

  video {
    z-index: 1;
    width: 100%;
    height: 800px;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size:  cover;
    position: relative
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    margin-top: 0;
    background-image: linear-gradient(
      to top,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.5)
    );
    width: 100%;
    padding-bottom: 100px;
  }

  /* SECTION 1*/

  #section-1 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 50px;
  }

  #bienvenidos-div {
    background-color: #6f00ff;
    width: fit-content;
    box-shadow: var(--shadow2-1) 5px 5px, var(--shadow2-2) 10px 10px, var(--shadow2-3) 15px 15px, var(--shadow2-4) 20px 20px, var(--shadow2-5) 25px 25px;
  }

  #bienvenidos {
    font-size: 40px;
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
    padding: 10px 20px;
    color: whitesmoke;
    transition: 1s;
  }

  #la-barbieria-div {
    margin-right: 20px;
    background-color: #0e0e0e;
    width: fit-content;
    box-shadow: var(--shadow2-1) 5px 5px, var(--shadow2-2) 10px 10px, var(--shadow2-3) 15px 15px, var(--shadow2-4) 20px 20px, var(--shadow2-5) 25px 25px;
  }

  #la-barbieria {
    font-size: 55px;
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
    color: whitesmoke;
    padding: 10px 20px;
    transition: 1s;
  }

  #descripcion {
    font-size: 25px;
    padding: 50px 20px 20px 20px;
    transition: 1s;
  }

  /* PARALLAX */
  
  .parallax1{
    position: relative;
    width: 90%;
  }

  /* SECTION 2 */

  #section-2 {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    position: relative;
    width: 100%;
  }

  .mySwiper2 .swiper-slide {
    transition: 0.5s all ease-in-out;
  }

  #section-2 #cuadro {
    overflow: hidden;
  }

  #section-2 img {
    width: 400px;
    height: 400px;
    object-fit: cover;
    filter: grayscale(0);
    transition: all .3s ease-in-out;
  }

  #section-2 img:hover {
    transform: scale(1.1);
  }

  /* SECTION 3 */

  #section-3 {
    flex-wrap:wrap;
    justify-content: center;
    align-items: center;
    padding: 50px;
    overflow: hidden;
    margin-top: 20px;
    display: flex;
  }

  #section-3 #inner-3-div {
    width: 90%;
    display: flex;
    flex-wrap:nowrap;
    justify-content: center;
    align-items: center;
    left: 52.5%;
    position: relative;
  }

  #section-3 #descripcion-3 {
    font-size: 25px;
    width: 90%;
    padding: 20px 20px 0 20px;
    transition: 1s;
  }

  #section-3 #imagen-div {
    margin-right: 100px;
  }

  .box {
    width: 350px;
    height: 350px;
    position: relative;
    animation: spin 25s ease-in-out infinite alternate-reverse;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      border-radius: 50%;
      border: 2px dashed rgb(0, 0, 0);
      transform: scale(1.1);
    }
  }

  .box img {
    width: 350px;
    height: 350px;
    border-radius: 50%;
  }

  #imagen-movil-1 {
    display: none;
  }

  /* SECTION 4 */

  #section-4 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  #section-4 #inner {
    width: 90%;
    padding: 50px;
  }

  #section-4 #titulo-4-div p {
    font-size: 40px;
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
    color: whitesmoke;
    padding: 20px;
    width: fit-content;
    transition: 1s;
    background-color: #0e0e0e;
    box-shadow: var(--shadow2-1) 5px 5px, var(--shadow2-2) 10px 10px, var(--shadow2-3) 15px 15px, var(--shadow2-4) 20px 20px, var(--shadow2-5) 25px 25px;
    transition: 1s;
  }

  #section-4 #boton-div {
    width: 100%;
  }

  #section-4 #boton-div img {
    background-color: rgba(16,46,72,0.6); 
    background-blend-mode: multiply;
  }

  #section-4 #boton-div .content {
    position: relative;
    overflow: hidden;
  }

  .content:hover .content-overlay{
    opacity: 1;
  }

  .content-image{
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 200px;
    filter: brightness(60%);
  }

  .content-details {
    position: absolute;
    text-align: center;
    width: 100%;
    top: 45%;
  }

  .content h3 {
    color: #fff;
    transition: all .8s ease-out;
    letter-spacing: 0.15em;
    will-change: transform;
    font-size: 30px;
    font-family: 'Poppins', sans-serif;
    font-weight: 900;
    text-transform: uppercase;
  }

  .content:hover h3 {
    -webkit-transform: scale(1.1);
    transform: scale(1.3);
    color: black;
  }

  /* TITLE TRANSITION */

  .slide-left-enter-active {
    transition: all 1s ease-out;
  }

  .slide-left-leave-active {
    transition: all 3s cubic-bezier(1, 0.5, 0.8, 1);
  }

  .slide-left-enter-from,
  .slide-left-leave-to {
    transform: translateX(-220px);
    opacity: 0;
  }

  @keyframes morph {
    0% {border-radius: 40% 60% 80% 30% / 60% 30% 70% 40%;} 
    100% {border-radius: 30% 60% 30% 80%;} 
  }

  @keyframes spin {
    0% {
      transform: rotate(5deg);
    }
    45% {
      transform: rotate(-5deg);
    }
  }

  @media screen and (max-width: 1350px) {
    #section-1 {
      padding: 10px;
      margin-top: 10px;
    }

    #section-4 #inner{
      padding: 10px;
      margin-bottom: 40px;
      margin-top: 40px;
    }
  }

  @media screen and (max-width: 1190px) {
    #section-3 #descripcion-3 {
      width: 100%;
    }

    #section-3 #imagen-div {
      display: none;
    }

    #imagen-movil-1 {
      display: block;
    }

    #imagen-movil-1 {
      background-image: url("@/assets/images/herramientas-labarbieria.jpeg");
      width: 100%;
      height: 200px;
      background-attachment: fixed;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      display: none;
    }
  }

  @media screen and (max-width:900px) {
    video {
      z-index: 1;
      width: 100%;
      height: 400px;
    }

    #bienvenidos {
      font-size: 26px;
    }

    #la-barbieria {
      font-size: 34px;
    }

    #descripcion {
      font-size: 18px;
    }

    #section-3 {
      padding: 10px;
      margin-top: 0;
    }

    #section-3 #descripcion-3 {
      font-size: 18px;
      width: 100%;
      padding-top: 20px;
    }

    #section-4 {
      padding: 0;
    }

    #section-4 #inner {
      padding: 10px;
      margin-bottom: 30px;
      margin-top: 10px;
      padding-right: 30px;
  }

    #section-4 #titulo-4-div {
      padding: 0px;
    }

    #section-4 p:hover {
      background-color: #6f00ff;
    }

    #section-4 #titulo-4-div p {
      font-size: 26px;
    }

    #section-4 #boton-div{
      margin-top: 10px;
    }
  }

  @media screen and (max-width:500px) {
    .video-web-div {
      display: none;
    }

    .video-movil-div {
      display: block;
    }

    .parallax1 {
      width: 90%;
    }

    #bienvenidos {
      font-size: 20px;
    }

    #la-barbieria {
      font-size: 28px;
    }

    #descripcion {
      padding: 35px 20px 0px 20px;
      font-size: 15px;
    }

    #section-3 {
      margin: 0px;
      padding: 10px;
    }

    #section-3 #descripcion-3 {
      font-size: 15px;
      padding: 5px 20px 5px 20px;
    }

    #section-4 {
      padding: 0;
    }

    #section-4 #inner {
      padding: 10px;
      padding-right: 20px;
      width: 90%;
    }

    #section-4 #titulo-4-div {
      width: 100%;
      padding: 0px;
    }

    #section-4 #titulo-4-div p {
      font-size: 20px;
    }

    #section-4 #titulo-4-div p:hover {
    background-color: #000000;
  }
    
  }

  @media screen and (max-width:400px) {
    .mySwiper {
      height: 300px;
    }
  }

  @media screen and (max-width:300px) {
    .mySwiper {
      height: 200px;
    }
  }

  @media screen and (max-width:200px) {
    .mySwiper {
      height: 100px;
    }
  }




</style>
