<template>
    <div class="academia-view">
        <div id="section-1">
            <div class="parallax1">
                <transition name="slide-left">
                    <div id="titulo-1" v-if="showTitulo1" class="layer-2" data-offset="-50">
                        <p>Academia</p>
                    </div>
                </transition>
            </div>
        </div>

        <transition name="slide-left">
            <div id="section-2-academia" v-if="showBody">
                <div id="inner-academia">
                    <p>
                        La Barbieria es una prestigiosa academia de peluquería que ha 
                        dejado una huella significativa en la industria del cuidado del 
                        cabello. Con una trayectoria envidiable y una historia rica, se 
                        ha convertido en un referente para aquellos apasionados por el 
                        arte de la peluquería. <br><br>

                        Su fundador, Gabriel Barbieri, un talentoso y visionario peluquero,
                        creía firmemente en la importancia de brindar una experiencia única
                        y de alta calidad a sus clientes. Con ese objetivo en mente, decidió 
                        establecer una academia de peluquería para formar a una nueva generación 
                        de profesionales excepcionales. <br><br>

                        Desde sus inicios, la academia se destacó por su enfoque en la excelencia 
                        técnica y la creatividad. Los estudiantes que ingresaban a la academia recibían 
                        una formación integral que abarcaba desde las técnicas básicas de corte y 
                        peinado hasta las últimas tendencias en coloración y diseño de cabello. 
                        La filosofía de la academia se basaba en el compromiso de impulsar la innovación 
                        y fomentar la pasión por el oficio. <br><br>

                        En resumen, La Barbieria ha dejado una huella perdurable en la industria de la 
                        peluquería gracias a su enfoque en la excelencia, la creatividad y la formación 
                        integral de sus estudiantes. Su trayectoria y su historia son testimonio de su 
                        compromiso con la calidad y su contribución al crecimiento y la evolución de la 
                        profesión.
                    </p>
                    <div id="imagen-academia-div">
                        <img src="@/assets/images/academia1.jpeg" alt="">
                    </div>
                    
                </div>
            </div>
        </transition>
    </div>
</template>
   
<script>
    export default {
        name: 'CursosView',
        data() {
        return {
            showTitulo1:false,
            showBody:false
        }
        },
        mounted() {
            document.title = 'Academia | Academia La Barbieria'
            setTimeout(() => {
                this.showTitulo1 = true;
            }, 200);
            setTimeout(() => {
                this.showBody = true;
            }, 800);
            setTimeout(() => {
                this.$store.state.footer = true;
            }, 2000);
            //this.$store.dispatch('parallax')
        },
        components: {
        },
        methods: {
            
        },
    }
</script>
   
   
<style>
    .academia-view {
        padding-top: 110px;
        min-height: 1000px;
        margin-bottom: 100px;
        text-align: center;
        transition: 1s;
    }

    #section-2-academia {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 0px 50px;
    }

    #inner-academia {
        display: flex;
        align-items: center;
        width: 90%;
        position: relative;
        flex-wrap: wrap;
    }

    #inner-academia p {
        text-align: left;
        padding: 20px;
        background-color: rgb(194, 194, 194);
        box-shadow: rgba(0, 0, 0, 0.301) 2.4px 2.4px 3.2px;
    }

    #imagen-academia-div {
        width: 100%;
        margin-top: 50px;
    }

    #imagen-academia-div img {
        width: 100%;
        height: 450px;
        object-fit: cover;

    }


    @media only screen and (max-width: 1350px) {
        .academia-view #section-1 {
            padding: 40px 10px;
        }

        #section-2-academia {
            padding: 10px;
        }
    }

    @media only screen and (max-width: 1360px) {
       
    }

    @media only screen and (max-width: 1180px) {
       
    }

    @media only screen and (max-width: 1000px) {
        .academia-view #section-1 {
            padding: 20px 10px;
        }

        .academia-view {
            min-height: 0;
        }

    }

   
    @media only screen and (max-width: 900px) {
        #section-2-academia {
            padding: 20px 10px 0 10px;
        }
    }
   
     @media only screen and (max-width: 500px) {
        #section-2-academia {
            padding: 20px 10px 0 10px;
        }

        #inner-academia p {
            padding: 20px 10px;
            font-size: 15px;
        }

        #imagen-academia-div img {
            height: 300px;
        }
    
     }

     @media only screen and (max-width: 400px) {
       
     }

   </style>