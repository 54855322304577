<template>
   <footer>
    <div id="copyright">
      <div class="redes-div">
        <div>
          <a href="https://www.instagram.com/labarbieriamdp/" target="_blank"><img src="@/assets/images/insta.png" aria-label="Instagram link" width="30" height="30" alt=""></a>
        </div>

        <div>
          <a href="https://www.facebook.com/labarbieriamdp/" target="_blank"><img src="@/assets/images/face.png" aria-label="Facebook link" width="30" height="30" alt=""></a>
        </div>

        <div>
          <img src="@/assets/images/whatsapp.png" aria-label="Conectar por Whatsapp" width="30" height="30" alt="" @click="mandarWhatsApp">
        </div>
      </div>
      
      <p>&copy; {{ new Date().getFullYear() }} La Barbieria Academia & Peluqueria</p>
    </div>
   </footer>
   
</template>
  
  <script>
  import { GoogleMap, Marker, CustomMarker} from "vue3-google-map";

  export default {
    name: 'FooterComp',
    data() {
      return {
        center: { lat: -37.954643, lng: -57.548370 },
      }
    },
    mounted() {
      
    },
    components: {
      GoogleMap, 
      Marker, 
      CustomMarker
    },
    methods: {
      async mandarWhatsApp (e) {
        let a= document.createElement('a');
        const fecha = e.target.dataset.fecha
        const hora = e.target.dataset.hora
        const telefono = e.target.dataset.telefono
        a.target= '_blank';
        a.href= "https://wa.me/5492235748516/?text=Hola, estoy interesado en los cursos, me podrias dar mas info por favor? Gracias!"
        a.click();
    },
    }
  }
  </script>
  
  
  <style>

  footer {
    background-color: #0e0e0e;
    color: white;
    text-align: center;
    bottom: 0;
    width: 100%;
  }

  .gmnoprint, .gm-style-cc {
    display: none;
  }

  .redes-div div {
    display: inline-table;
    padding: 10px;
  }

  .redes-div div img {
    width: 30px;
    filter: invert(100%);
    cursor: pointer;
  }

  #datos-footer-div {
    padding: 20px;
  }

  #copyright {
    background-color: #111111;
    text-align: center;
    padding: 20px;
  }
  
  
    @media screen and (min-width: 690px) and (max-width: 1050px) {
   
  
  
    }
  
    @media only screen and (max-width: 690px) {
  
    }
  
    @media only screen and (max-width: 370px) {
   
    }
  
  </style>