<template>
    <div class="servicios-view">
        <div id="servicios-cliente-div">
            <div class="is-loading-bar sound-wave" v-if="this.$store.state.isLoading">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
            
            <div id="section-1">
                <div class="parallax1">
                    <transition name="slide-left">
                        <div id="titulo-1" v-if="showTitulo1" class="layer-2" data-offset="-50">
                            <p>Servicios</p>
                        </div>
                    </transition>
                </div>
            </div>

            <div id="section-2-servicios">
                <transition name="slide-left">
                    <div id="servicios-div" v-if="showBody">
           
                            <div v-for="item in servicios" :key="item" id="servicios-categorias-div">
                                <h1 id="categoria-servicios">{{ item.categoria }}</h1>
                                <div class="servicios-items-div">
                                    <div id="table-wrapper-servicios-cliente">
                                        <div id="tabla-servicios-cliente">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th></th>
                                                        <th></th>
                                                    </tr> 
                                                </thead>

                                                <tbody>
                                                    <tr v-for="servicio in item.servicios"  :key="servicio">
                                                        <td data-label="SERVICIO" id="td-servicio-cliente-nombre">{{ servicio.servicio }}</td>
                                                        <td data-label="DURACION" id="td-servicio-cliente-duracion">{{ servicio.duracion }}MIN.</td>
                                                        <td data-label="PRECIO" id="td-servicio-cliente-precio">${{ servicio.precio }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
          
                    </div>
                </transition>
            </div>
        </div>
    </div>
  </template>

<script>
import axios from 'axios'

export default {
   
    name: 'ServiciosView',
    data() {
        return {
            servicios:[],
            showTitulo1:false,
            showBody:false
        }
    },
    components: { 

    },
    mounted() {
        document.title = 'Servicios | Academia La Barbieria'
        this.getServicios();
        setTimeout(() => {
            this.showTitulo1 = true;
        }, 200);
        setTimeout(() => {
            this.showBody = true
        }, 800);
        setTimeout(() => {
            this.$store.state.footer = true;
        }, 2000);
        //this.$store.dispatch('parallax')
    },
    methods: {
        async getServicios() {
            this.$store.commit('setIsLoading', true)

            let servicios = []
            await axios
                .get('/api/v1/servicios/por-categoria')
                .then(response => {
                    for (let i = 0; response.data.length > i; i++) {
                        if (response.data[i].length > 0) {
                            let item = {
                                'categoria':response.data[i][0]['categoria'],
                                'servicios':[]
                            }
                            for (let x = 0; response.data[i].length > x; x++) {
                                item['servicios'].push(
                                    {
                                        'servicio':response.data[i][x]['nombre'],
                                        'precio':response.data[i][x]['precio'],
                                        'duracion':response.data[i][x]['get_duracion']
                                    }
                                )
                            }
                            servicios.push(item)
                        }
                    }
                    this.servicios = servicios
                })
                .catch(error => {
                    console.log(error)
                })
            this.$store.commit('setIsLoading', false)
        },
    }
}
</script>
  
  <style>
    .servicios-view {
        padding-top: 110px;
        text-align: center;
        min-height: 870px;
        margin-bottom: 100px;
    }

    #section-2-servicios {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 0px 30px;
    }

    #servicios-div{
        display: flex;
        flex-wrap: wrap;
        width: 90%;
        position: relative;
    }

    #servicios-categorias-div {
        padding: 10px 20px;
        background-color: #111111;
        margin:20px;
        box-shadow: var(--shadow2-1) 5px 5px, var(--shadow2-2) 10px 10px, var(--shadow2-3) 15px 15px, var(--shadow2-4) 20px 20px, var(--shadow2-5) 25px 25px;
    }

    #categoria-servicios {
        font-size: 30px;
        font-weight: bold;
        color: whitesmoke;
        font-family: 'Poppins', sans-serif;
    }

    #table-wrapper-servicios-cliente {
        position:relative;
    }

    #tabla-servicios-cliente {
        width: 450px;
    }

    #tabla-servicios-cliente table {
        width: 100%;
    }

    #td-servicio-cliente-nombre {
        width: 200px;
        text-align: left;
        font-weight: bold;
        color: whitesmoke;
    }

    #td-servicio-cliente-duracion {
        width: 100px;
        text-align: center;
        font-weight: bold;
        color: whitesmoke;
    }

    #td-servicio-cliente-precio {
        width: 100px;
        text-align: right;
        color: whitesmoke;
        font-weight: bold;
    }

    #tabla-servicios-cliente table th {
        padding: unset;
    }

    #tabla-servicios-cliente table td {
        padding: 5px;
    }

    #tabla-servicios-cliente table tbody tr:hover {
        background-color: transparent;
    }

    @media screen and (max-width: 1350px) {
        .servicios-view #section-1 {
            padding: 40px 10px;
        }

        #servicios-categorias-div {
            margin: 10px 20px 20px 10px;
        }

        #section-2-servicios {
            padding: 0px ;
        }
    }

    @media screen and (max-width: 1000px) {
        .servicios-view #section-1 {
            padding-top: 20px;
        }

        .servicios-view {
            min-height: 0;
            margin-bottom: 50px;
        }
    
    }

    @media screen and (max-width: 900px) {
        #section-2-servicios {
            padding: 0px;
        }
    
    }

    @media screen and (max-width: 630px) {
        #tabla-servicios-cliente {
            width: 400px;
        }
        #servicios-categorias-div {
            margin: 0 10px 40px 10px
        }
    }

    @media screen and (max-width: 520px) {
        #tabla-servicios-cliente {
            width: 370px;
        }
        #servicios-categorias-div {
            margin: 0 10px 40px 10px
        }
    }

    @media screen and (max-width: 500px) {
        .servicios-view #section-1 {
            padding: 20px 10px 40px 10px;
        }

        #tabla-servicios-cliente {
            width: unset;
        }
        #servicios-categorias-div {
            margin: 0 20px 40px auto
        }

        #categoria-servicios {
            font-size: 24px;
        }

        #tabla-servicios-cliente table td {
            font-size: 15px;
        }
    }

    @media screen and (max-width: 400px) {
        #tabla-servicios-cliente {
            width: unset;
        }

        #tabla-servicios-cliente table td {
            font-size: 14px;
        }
    }

    @media screen and (max-width: 330px) {
        #tabla-servicios-cliente table td {
            font-size: 13px;
        }
    }
  </style>