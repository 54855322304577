<template>
    <div class="certificado-view">
        <div id="section-1">
            <div class="parallax1">
                <transition name="slide-left">
                    <div id="titulo-1" v-if="showTitulo1" class="layer-2" data-offset="-50">
                        <p>Certificados</p>
                    </div>
                </transition>
            </div>
        </div>

        <div id="section-2-certificado">
            <transition name="slide-left">
                <div id="validacion-texto-div" v-if="showBody">
                    <div class="text-left shadow-lg" id="ingresar-dni-div">
                        <div id="titulo-certificado">
                            <p>Sistema de validación</p>
                        </div>
                        
                        <div class="relative z-0 text-left inline-table mt-5" id="dni-div">
                            <input type="text" v-model="dni" id="floating_standard" class="block py-2.5 px-0 text-sm text-black bg-transparent border-0 border-b-2 border-gray-500 appearance-none dark:text-black dark:border-black-600 dark:focus:border-black focus:outline-none focus:ring-0 focus:border-black peer" placeholder=" " />
                            <label for="floating_standard" class="absolute text-sm text-black dark:text-black duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-black peer-focus:dark:text-grey-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">DNI</label>
                            <p v-if="errorDni" class="mt-1 text-xs text-red-600 dark:text-red-500 absolute">{{ errorDni }}</p>
                        </div>

                        <div class="text-center inline-table ml-5" id="boton-verificar-div"> 
                            <button type="button" id="boton-verificar-dni" class="raise" data-token="" @click="getRecaptcha">Verificar</button>
                        </div>
                    </div>

                    <div class="text-right shadow-lg" id="parrafo-div">
                        <p>
                            Verifica la autenticidad de los certificados emitidos por 
                            <span class="font-semibold">La Barbieria Academia & Barberia</span> ingresando el número de DNI del alumno 
                            (Documento de identidad del país de origen). Nuestro sistema consulta nuestra 
                            base de datos para confirmar la validez del certificado de manera rápida y segura.
                        </p>
                    </div>
                </div>
            </transition>
        </div>

        <div class="is-loading-bar sound-wave" style="margin-top: 50px;" v-if="this.$store.state.isLoading">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>

        <div id="certificados-div" v-if="certificados">
            <transition name="slide-fade">
                <div v-if="certificados" id="certificados">
                    <div v-for="certificado in certificados" :key="certificado.id" id="certificado">
                        <p><span>ALUMNA/O:</span> {{ certificado.alumno }}</p>
                        <p><span>TITULO:</span> {{ certificado.curso }}</p>
                        <button class="btn btn-info raise" @click="ver" :data-id="certificado.id">
                            Ver
                        </button>
                    </div>
                </div>
            </transition>

        </div>

        <div id="section-3-certificado">
            <transition name="slide-left">
                <div id="ejemplo-div" v-if="showBody">
                    <div id="inner-certificado" class="shadow-lg">
                        <p>
                            A continuación podrán observar un ejemplo de certificado. <br>
                            Cada certificado expedido por está escuela posee un enlace permanente y detalles de autenticidad que lo hacen único.
                        </p>

                        <div id="certificado-imagen-div">
                            <img id="certificado-imagen" src="@/assets/images/certificado-ejemplo.jpeg" alt="">
                        </div>
                    </div>
                </div>
            </transition>
        </div>

        <Transition name="slide-fade">
            <ToastComp 
                v-if="toast"
                :mensaje="mensaje"
                :tipo="tipo"
            />
        </Transition>
    </div>
 </template>
   
<script>
    import ToastComp from '@/components/ToastComp.vue'
    import axios from 'axios'
   

    export default {
        name: 'ContactoView',
        data() {
        return {
            dni:'',
            errorDni:'',
            toast:false,
            mensaje:'',
            tipo:'',
            certificado:'',
            certificados:[],
            showTitulo1:false,
            showBody:false
        }
        },
        mounted() {
            document.title = 'Validar certificado | Academia La Barbieria'
            setTimeout(() => {
                this.showTitulo1 = true;
            }, 200);
            setTimeout(() => {
                this.showBody = true
            }, 800);
            setTimeout(() => {
                this.$store.state.footer = true;
            }, 2000);
            //this.$store.dispatch('parallax')
        },
        components: {
            ToastComp
        },
        methods: {
            async getRecaptcha(e) {
                this.$store.commit('setIsLoading', true)

                if (!this.dni) {
                    this.$store.commit('setIsLoading', false)
                    this.errorDni = 'Requerido'
                    setTimeout(() => this.errorDni = '', 2000)
                }else if (this.dni.length < 7) {
                    this.$store.commit('setIsLoading', false)
                    this.errorDni = 'DNI Incorrecto'
                    setTimeout(() => this.errorDni = '', 2000)
                }
                
                e.preventDefault();

                if (!this.errorDni) {
                    const data = {
                        dni:this.dni,
                        token:e.target.dataset.token
                    }

                    this.horasDisponibles = []
                    this.reservarTexto = false
                    this.mostrarHoras = true
                    this.$store.commit('setIsLoading', true)
                    const boton = document.getElementById('boton-verificar-dni');
                    grecaptcha.ready(function() {
                        grecaptcha.execute('6Le7L8kmAAAAALZTlKhFj2CJXwZ1nxCXy506leyn')
                        .then(response => {
                            // Add your logic to submit to your backend server here.
                            boton.dataset.token = response;
                        })
                    });

                    setTimeout(() => {
                        this.verificarDni(e)
                    }, 2000);
                }
            },
            async verificarDni(e) {
                const data = {
                    dni:this.dni,
                    token:e.target.dataset.token
                }

                await axios
                .post('/api/v1/certificados/', data)
                .then(response => {
                    if (response.data == 'robot') {
                        this.mensaje = 'Na na na robot'
                        this.tipo = 'error'
                        this.toast =true
                        setTimeout(() => this.toast = false, 3000)
                    }else {
                        this.certificados = response.data
                    }
                })
                .catch(error => {
                    this.mensaje = 'El numero de DNI ingresado no se encuentra.\nIntentalo nuevamente.\nGracias!'
                    this.tipo = 'error'
                    this.toast =true
                    setTimeout(() => this.toast = false, 3000)
                })
                this.$store.commit('setIsLoading', false)  
            },
            async ver(e) {
                this.$store.commit('setIsLoading', true)
                const data = {
                    id:e.target.dataset.id
                }
                await axios
                    .post('/api/v1/certificados/pdf/', data, {responseType: 'arraybuffer'})
                    .then(response => {
                        const blob = new Blob([response.data], { type: 'application/pdf' });
                        const href = window.URL.createObjectURL(blob);
                        var a = document.createElement("a");
                        document.body.appendChild(a);
                        a.style = "display: none";
                        a.href = href;
                        a.target = '_blank'
                        a.click();             
                        setTimeout(function(){
                            document.body.removeChild(a);
                            window.URL.revokeObjectURL(href);
                        }, 100); 
                    })
                    .catch(error => {
                        this.$store.commit('setIsLoading', false)
                        this.mensaje = 'No se puede abrir el certificado.\nIntentalo nuevamente.\nGracias!'
                        this.tipo = 'error'
                        this.toast =true
                        setTimeout(() => this.toast = false, 3000)
                    })
                    
                this.$store.commit('setIsLoading', false)
            }
            
        }
    }
</script>
   
   
<style lang="scss">
    .certificado-view {
        padding-top: 110px;
        margin-bottom: 20px;
        min-height: 1000px;
    }

    #section-2-certificado,
    #section-3-certificado {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 0px 50px;
    }

    #boton-verificar-dni {
        background-color: black;
        color: white;
        border-radius: 4px;
        padding: 10px 20px;
        font-size: 15px;
        transition: all .4s;
    }

    #boton-verificar-dni:focus {
        outline: none;
    }

    #validacion-texto-div {
        position: relative;
        width:90%;
        display: flex;
        align-items: center;
    }

    #ingresar-dni-div {
        max-width: 900px;
        margin-right: -15px;
        background-color: rgb(194, 194, 194);
        padding: 20px;
        padding-right: 40px;
        box-shadow: rgba(0, 0, 0, 0.322) 2.4px 2.4px 3.2px;
    }

    #parrafo-div {
        max-width: 600px;
        margin-right: 25px;
        margin-left: -15px;
        background-color: #0e0e0e;
        padding: 20px;
        box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
    }

    #parrafo-div p {
        color: whitesmoke;
    }

    #certificados-div {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 0px 50px;
    }

    #certificados {
        position: relative;
        width:90%;
        display: flex;
        align-items: center;
 
    }

    #certificado {
        width: 400px;
        margin-top: 50px;
        margin-right: 20px;
        padding: 20px;
        background-color: #999999;
        box-shadow: rgba(0, 0, 0, 0.411) 2.4px 2.4px 3.2px;
    }
    
    #certificado p span {
        font-weight: 900;
    }

    #inner-certificado {
        background-color: whitesmoke;
        padding: 20px;
        box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
    }

    #certificado-imagen {
        width: 100%;
    }

    #titulo-certificado p {
        font-size: 2vw;
        text-transform: uppercase;
    }

    .btn{
        padding: 10px 17px; 
        border-radius: 3px; 
        background: #111111; 
        border: none; 
        font-size: 12px; 
        margin: 10px 0;
        color: whitesmoke;
        transition: all .4s;
    }

    #dni-div #floating_standard {
        width: 120px;
    }

    #section-3-certificado p{
        padding-bottom: 20px;
    }

    #ejemplo-div {
        display: flex;
        align-items: center;
        position: relative;
        width:90%;
        margin-top: 50px;
    }

    @media only screen and (max-width: 1350px) {
        .certificado-view #section-1 {
            padding: 40px 10px;
        }

        #section-2-certificado,
        #section-3-certificado {
            padding: 0 10px;
        }
    }

    @media only screen and (max-width: 1000px) {
        .certificado-view {
            padding-top: 110px;
            min-height: 0;
        }

        #certificado-ejemplo {
            max-width: 700px;
        }
    }
   
    @media only screen and (max-width: 900px) {
        .certificado-view #section-1 {
            margin: 0 auto 40px auto;
            padding: 40px 30px 0px 10px;
        }

        #validacion-texto-div {
            justify-content: flex-start;
            flex-direction: column;
        }

        #parrafo-div p,
        #inner-certificado p {
            text-align: left;
            font-size: 14px;
        }

        #ingresar-dni-div {
            margin: 0 0 -20px 0 !important;
        }

        #certificados-div {
            padding-top: 0;
            padding-bottom: 0;
        }

        #certificado {
            margin-top: 20px;
        }

        #ejemplo-div {
            margin-top: 20px;
        }

        #parrafo-div,
        #inner-certificado {
            margin-right: 0px;
            margin-left: 0px;
        }

        #titulo-certificado p {
            font-size: 30px;
        }
    }

    @media only screen and (max-width: 700px) {
        #titulo-certificado p {
            font-size: 5vw;
            text-transform: uppercase;
        }
    }

    @media only screen and (max-width: 600px) {
        .certificado-view #section-1 {
            margin: 0 auto 40px auto;
            padding: 20px 30px 0px 10px;
        }

        #titulo-certificado p {
            font-size: 5vw;
            text-transform: uppercase;
        }

    }

    @media only screen and (max-width: 400px) {
        #titulo-certificado p {
            font-size: 6vw;
            text-transform: uppercase;
        }

        #dni-div input,
        #boton-verificar-div button {
            font-size: 12px;
        }

        #boton-verificar-div {
            margin-left: 0;
            margin-bottom: 10px;
        }
    }

    @media only screen and (max-width: 275px) {
        #certificado {
            flex-direction: column;
        }
    }

   </style>