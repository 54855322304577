<template>
    <div class="franquicia-view">
        <div id="section-1">
            <div class="parallax1">
                <transition name="slide-left">
                    <div id="titulo-1" v-if="showTitulo1" class="layer-2" data-offset="-50">
                        <p>FRANQUICIA DE LA ACADEMIA LA BARBIERIA</p>
                    </div>
                </transition>
            </div>
        </div>

        <transition name="slide-left">
            <div id="section-2-franquicia" v-if="showBody">
                <div id="inner-franquicia">
                    <p>
                        <span>La Academia de La Barbieria te invita a formar parte de nuestra 
                        red de emprendedores exitosos. Al unirte a nuestra franquicia, 
                        no solo adquirís un negocio, sino también la oportunidad de destacar 
                        como referente educativo. ¿Qué te ofrecemos?</span>
                    </p>

                    <p>
                        <span>Capacitación Excepcional:</span> Proporcionamos programas de formación para 
                        que tu salón no solo sobresalga en servicios de belleza, sino que también 
                        pueda ofrecer cursos siguiendo la prestigiosa línea de nuestra academia.
                    </p>

                    <p>
                        <span>Certificación Nacional:</span> Brindamos la posibilidad de otorgar matrículas con 
                        certificación nacional. Esto significa que los graduados podrán abrir su 
                        propio salón de belleza respaldados por una certificación reconocida en todo 
                        el país.
                    </p>

                    <p>
                        <span>Ampliación de Servicios:</span> Si brindás servicios adicionales de estética, 
                        nos comprometemos a evaluar y certificarlos, siempre que cumpla con los estándares 
                        establecidos por nuestra academia.
                    </p>
                    
                    <p>
                        <span>Visibilidad y Clientela:</span> Proveemos clientela y publicidad a través de nuestra 
                        página web. Los clientes pueden encontrar fácilmente tu franquicia y acceder a 
                        tus servicios. Además, mantenemos una sólida presencia en redes sociales para 
                        maximizar tu alcance.
                    </p>
                    
                    <p>
                        <span>¿Estás listo para llevar tu barbería-peluquería al siguiente nivel? Unite a 
                        nosotros y transforma tu pasión en un éxito empresarial. La Academia de la 
                        Barbieria te brinda el respaldo necesario para impulsar tu emprendimiento. 
                        ¡Te esperamos!</span>
                    </p>
                    <!-- <div id="imagen-franquicia-div">
                        <img src="@/assets/images/academia1.jpeg" alt="">
                    </div> -->
                    
                </div>
            </div>
        </transition>
    </div>
</template>
   
<script>
    export default {
        name: 'FranquiciaView',
        data() {
        return {
            showTitulo1:false,
            showBody:false
        }
        },
        mounted() {
            document.title = 'Franquicia | Academia La Barbieria'
            setTimeout(() => {
                this.showTitulo1 = true;
            }, 200);
            setTimeout(() => {
                this.showBody = true;
            }, 800);
            setTimeout(() => {
                this.$store.state.footer = true;
            }, 2000);
            //this.$store.dispatch('parallax')
        },
        components: {
        },
        methods: {
            
        },
    }
</script>
   
   
<style>
    .franquicia-view {
        padding-top: 110px;
        min-height: 800px;
        margin-bottom: 100px;
        text-align: center;
        transition: 1s;
    }

    #section-2-franquicia {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 0px 50px;
    }

    #inner-franquicia {
        display: flex;
        align-items: center;
        width: 90%;
        position: relative;
        flex-wrap: wrap;
    }

    #inner-franquicia p {
        text-align: left;
        padding: 20px;
        background-color: rgb(194, 194, 194);
        box-shadow: rgba(0, 0, 0, 0.301) 2.4px 2.4px 3.2px;
    }

    #inner-franquicia p span {
        font-family: 'Poppins', sans-serif;
    }

    #imagen-franquicia-div {
        width: 100%;
        margin-top: 50px;
    }

    #imagen-franquicia-div img {
        width: 100%;
        height: 450px;
        object-fit: cover;

    }


    @media only screen and (max-width: 1350px) {
        .franquicia-view #section-1 {
            padding: 40px 10px;
        }

        #section-2-franquicia {
            padding: 10px;
        }
    }

    @media only screen and (max-width: 1360px) {
       
    }

    @media only screen and (max-width: 1180px) {
       
    }

    @media only screen and (max-width: 1000px) {
        .franquicia-view #section-1 {
            padding: 20px 10px;
        }

        .franquicia-view {
            min-height: 0;
        }

    }

   
    @media only screen and (max-width: 900px) {
        #section-2-franquicia {
            padding: 20px 10px 0 10px;
        }
    }
   
     @media only screen and (max-width: 500px) {
        #section-2-franquicia {
            padding: 20px 10px 0 10px;
        }

        #inner-franquicia p {
            padding: 20px 10px;
            font-size: 15px;
        }

        #imagen-franquicia-div img {
            height: 300px;
        }
    
     }

     @media only screen and (max-width: 400px) {
       
     }

   </style>
