<template>
    <div class="cursos-view">
        <div id="section-1">
            <div class="parallax1">
                <transition name="slide-left">
                    <div id="titulo-1" v-if="showTitulo1" class="layer-2" data-offset="-50">
                        <p>Cursos</p>
                    </div>
                </transition>
            </div>
        </div>

        <transition name="slide-left">
            <div id="section-2-cursos" v-if="showCursos">
                <CursoComp />
            </div>
        </transition>
    </div>
</template>
   
<script>
    import CursoComp from '@/components/CursoComp.vue'
    export default {
        name: 'CursosView',
        data() {
        return {
            showTitulo1:false,
            showCursos:false
        }
        },
        mounted() {
            document.title = 'Cursos | Academia La Barbieria'
            setTimeout(() => {
                this.showTitulo1 = true;
            }, 200);
            setTimeout(() => {
                this.showCursos = true;
            }, 800);
            setTimeout(() => {
                this.$store.state.footer = true;
            }, 2000);
            //this.$store.dispatch('parallax')
        },
        components: {
           CursoComp
        },
        methods: {
            
        },
    }
</script>
   
   
<style>
    .cursos-view {
        padding-top: 110px;
        min-height: 800px;
        margin-bottom: 300px;
        text-align: center;
        transition: 1s;
    }

    .parallax1 {
        position: relative;
    }

    @media only screen and (max-width: 1350px) {
        .cursos-view #section-1 {
            padding: 40px 10px;
        }
    }

    @media only screen and (max-width: 1360px) {
       
    }

    @media only screen and (max-width: 1180px) {
       
    }

    @media only screen and (max-width: 1000px) {
        .cursos-view #section-1 {
            padding: 20px 10px;
        }

        .cursos-view {
            min-height: 0;
        }

    }

    @media screen and (min-width: 800px) and (max-width: 900px) {
        
    }
   
    @media only screen and (max-width: 800px) {
        
     }
   
     @media only screen and (max-width: 650px) {
     
    
     }

     @media only screen and (max-width: 400px) {
       
     }

   </style>