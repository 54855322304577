<template>
    <div class="credencial-view">
        <div id="section-1">
            <div class="parallax1">
                <transition name="slide-left">
                    <div id="titulo-1" v-if="showTitulo1" class="layer-2">
                        <p>Credenciales</p>
                    </div>
                </transition>
            </div>
        </div>

        <div id="section-2-credencial">
            <transition name="slide-left">
                <div id="validacion-texto-div" v-if="showBody">
                    <div class="text-left shadow-lg" id="ingresar-dni-div">
                        <div id="titulo-certificado">
                            <p>Sistema de Descuentos</p>
                        </div>
                        
                        <div class="relative z-0 text-left inline-table mt-5" id="dni-div">
                            <input type="text" v-model="dni" id="floating_standard" class="block py-2.5 px-0 text-sm text-black bg-transparent border-0 border-b-2 border-gray-500 appearance-none dark:text-black dark:border-black-600 dark:focus:border-black focus:outline-none focus:ring-0 focus:border-black peer" placeholder=" " />
                            <label for="floating_standard" class="absolute text-sm text-black dark:text-black duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-black peer-focus:dark:text-grey-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">DNI</label>
                            <p v-if="errorDni" class="mt-1 text-xs text-red-600 dark:text-red-500 absolute">{{ errorDni }}</p>
                        </div>

                        <div class="text-center inline-table ml-5" id="boton-verificar-div"> 
                            <button type="button" id="boton-verificar-dni" class="raise" data-token="" @click="getRecaptcha">Verificar</button>
                        </div>
                    </div>

                    <div class="text-right shadow-lg" id="parrafo-div">
                        <p>
                            Como alumna/o de La Barbieria Academia, tenes la ventaja de acceder a descuentos increibles. 
                            Descarga tu credencial y presentala en los comercios adheridos.
                        </p>
                    </div>
                </div>
            </transition>
        </div>

        <div class="is-loading-bar sound-wave" style="margin-top: 50px;" v-if="this.$store.state.isLoading">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>

        <div id="credencial-div" v-if="credencial.length != 0">
            <transition name="slide-fade">
                <div v-if="credencial.length != 0" id="certificados">
                    <div id="certificado">
                        <p><span>ALUMNA/O:</span> {{ credencial.nombre }} {{ credencial.apellido }}</p>
                        <button class="btn btn-info raise" @click="ver" :data-id="credencial.id">
                            Ver
                        </button>
                    </div>
                </div>
            </transition>
        </div>

        <div id="section-3-credencial">
            <transition name="slide-left">
                <div id="comercios-div" v-if="showBody">
                    <div v-for="comercio in comercios" :key="comercio.id" id="comercios-adheridos-div">
                        <p id="comercio-nombre-credenciales">{{ comercio.nombre }}</p>
                        <p id="comercio-ciudad-credenciales">{{ comercio.ciudad }}</p>
                        <p id="comercio-direccion-credenciales">{{ comercio.direccion }}</p>

                        <a v-if="comercio.website" :href="comercio.website" id="comercio-website-credenciales">Sitio Web</a>

                        <div id="redes-credenciales">
                            <div v-if="comercio.instagram">
                                <a :href="comercio.instagram"><img src="@/assets/images/insta.png" alt=""></a>
                            </div>

                            <div v-if="comercio.facebook">
                                <a :href="comercio.facebook"><img src="@/assets/images/face.png" alt=""></a>
                            </div>

                            <div v-if="comercio.whatsapp">
                                <img src="@/assets/images/whatsapp.png" alt="" :data-info="comercio.whatsapp" @click="whatsAppButton">
                            </div>
                        </div> 

                        <p id="porcentaje-off-credenciales"><span>{{ comercio.descuento }}% OFF</span></p>
                    </div>
                </div>
            </transition>
        </div>

        <Transition name="slide-fade">
            <ToastComp 
                v-if="toast"
                :mensaje="mensaje"
                :tipo="tipo"
            />
        </Transition>
    </div>
 </template>
   
<script>
    import ToastComp from '@/components/ToastComp.vue'
    import axios from 'axios'
   

    export default {
        name: 'CredencialView',
        data() {
        return {
            dni:'',
            errorDni:'',
            toast:false,
            mensaje:'',
            tipo:'',
            comercios:[],
            showTitulo1:false,
            showBody:false,
            credencial:[]
        }
        },
        mounted() {
            document.title = 'Credencial Alumno | Academia La Barbieria'
            setTimeout(() => {
                this.showTitulo1 = true;
            }, 200);
            setTimeout(() => {
                this.showBody = true
            }, 800);
            setTimeout(() => {
                this.$store.state.footer = true;
            }, 2000);
            //this.$store.dispatch('parallax')
            this.getComerciosAdheridos()
        },
        components: {
            ToastComp
        },
        methods: {
            async whatsAppButton (e) {
                let a = document.createElement('a');
                let whatsapp = e.target.dataset.info
                a.target= '_blank';
                a.href= `https://wa.me/549${whatsapp}/?text=Hola, soy alumn@ de La Barbieria Academia, me podrias brindar informacion sobre el descuento? Gracias!`
                a.click();
            },
            async getComerciosAdheridos() {
                await axios
                .get('/api/v1/comercios-adheridos/')
                .then(response => {
                    this.comercios = response.data
                })
                .catch(error => {
                    console.log(error)
                })
            },
            async getRecaptcha(e) {
                this.$store.commit('setIsLoading', true)
                if (!this.dni) {
                    this.$store.commit('setIsLoading', false)
                    this.errorDni = 'Requerido'
                    setTimeout(() => this.errorDni = '', 2000)
                }
                else if (this.dni.length < 7) {
                    this.$store.commit('setIsLoading', false)
                    this.errorDni = 'DNI Incorrecto'
                    setTimeout(() => this.errorDni = '', 2000)
                }
                
                e.preventDefault();

                if (!this.errorDni) {
                    const boton = document.getElementById('boton-verificar-dni');
                    grecaptcha.ready(function() {
                        grecaptcha.execute('6Le7L8kmAAAAALZTlKhFj2CJXwZ1nxCXy506leyn')
                        .then(response => {
                            // Add your logic to submit to your backend server here.
                            boton.dataset.token = response;
                        })
                    });

                    setTimeout(() => {
                        this.verificarDni(e)
                    }, 2000);
                }
            },
            async verificarDni(e) {
                const data = {
                    dni:this.dni,
                    token:e.target.dataset.token
                }

                await axios
                .post('/api/v1/credencial/', data)
                .then(response => {
                    if (response.data == 'robot') {
                        this.mensaje = 'Na na na robot'
                        this.tipo = 'error'
                        this.toast = true
                        setTimeout(() => this.toast = false, 3000)
                    }else if (response.data == 'dni no existe') {
                        this.mensaje = 'El numero de DNI ingresado no existe.\nIntentalo nuevamente.\nGracias!'
                        this.tipo = 'error'
                        this.toast =true
                        setTimeout(() => this.toast = false, 3000)
                    }else {
                        this.credencial = response.data
                    }
                })
                .catch(error => {
                    this.mensaje = 'El numero de DNI ingresado no se encuentra.\nIntentalo nuevamente.\nGracias!'
                    this.tipo = 'error'
                    this.toast =true
                    setTimeout(() => this.toast = false, 3000)
                    console.log(error)
                })
                this.$store.commit('setIsLoading', false)
            },
            async ver(e) {
                this.$store.commit('setIsLoading', true)
                const data = {
                    id:e.target.dataset.id
                }
                await axios
                    .post('/api/v1/credencial/pdf/', data, {responseType: 'arraybuffer'})
                    .then(response => {
                        const blob = new Blob([response.data], { type: 'application/pdf' });
                        const href = window.URL.createObjectURL(blob);
                        var a = document.createElement("a");
                        document.body.appendChild(a);
                        a.style = "display: none";
                        a.href = href;
                        a.target = '_blank'
                        a.click();             
                        setTimeout(function(){
                            document.body.removeChild(a);
                            window.URL.revokeObjectURL(href);
                        }, 100); 
                    })
                    .catch(error => {
                        this.$store.commit('setIsLoading', false)
                        this.mensaje = 'No se puede abrir la credencial.\nIntentalo nuevamente.\nGracias!'
                        this.tipo = 'error'
                        this.toast =true
                        setTimeout(() => this.toast = false, 3000)
                    })

                this.$store.commit('setIsLoading', false)
            }
            
        }
    }
</script>
   
   
<style lang="scss">
    .credencial-view {
        padding-top: 110px;
        margin-bottom: 20px;
        min-height: 950px;
    }

    #section-2-credencial,
    #section-3-credencial {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 0px 50px;
    }

    #section-2-credencial {
        margin-top: 20px;
    }

    #validacion-texto-div {
        position: relative;
        width:90%;
        display: flex;
        align-items: center;
    }

    #ingresar-dni-div {
        max-width: 900px;
        margin-right: -15px;
        background-color: rgb(194, 194, 194);
        padding: 20px;
        padding-right: 40px;
        box-shadow: rgba(0, 0, 0, 0.322) 2.4px 2.4px 3.2px;
    }

    #parrafo-div {
        max-width: 600px;
        margin-right: 25px;
        margin-left: -15px;
        background-color: #0e0e0e;
        padding: 20px;
        box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
    }

    #parrafo-div p {
        color: whitesmoke;
    }

    #credencial-div {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 0px 50px;
    }

    #credencial {
        position: relative;
        width:90%;
        display: flex;
        align-items: center;
 
    }

    #credencial {
        width: 400px;
        margin-top: 50px;
        margin-right: 20px;
        padding: 20px;
        background-color: #999999;
        box-shadow: rgba(0, 0, 0, 0.411) 2.4px 2.4px 3.2px;
    }
    
    #certificado p span {
        font-weight: 900;
    }

    #comercios-div {
        width:90%;
        margin-top: 50px;
    }

    #comercios-adheridos-div {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        background-color: rgba(128, 128, 128, 0.219);
        max-width: 400px;
        flex-direction: column;
        padding: 20px;
    }

    #comercios-adheridos-div p {
        font-size: 19px;
        text-align: center;
    }

    #comercio-nombre-credenciales {
        font-family: 'Poppins', sans-serif;
        font-size: 25px !important;
    }

    #comercio-nombre-credenciales,
    #comercio-direccion-credenciales {
        margin-bottom: 15px;
    }

    #comercio-website-credenciales {
        margin-bottom: 10px;
        background-color: black;
        color: whitesmoke;
        padding: 10px;
        border-radius: 4px;
    }

    #redes-credenciales {
        display: flex;
        flex-wrap: wrap;
    }

    #comercios-adheridos-div img {
        width: 50px;
        cursor: pointer;
        margin: 0 5px;
    }

    #porcentaje-off-credenciales {
        margin-top: 20px;
    }

    #porcentaje-off-credenciales span {
        font-family: 'Poppins', sans-serif;
        font-size: 35px;
    }

    .btn{
        padding: 10px 17px; 
        border-radius: 3px; 
        background: #111111; 
        border: none; 
        font-size: 12px; 
        margin: 10px 0;
        color: whitesmoke;
    }

    #dni-div #floating_standard {
        width: 120px;
    }


    @media only screen and (max-width: 1350px) {
        .credencial-view #section-1 {
            padding: 40px 10px;
        }

        #section-2-credencial,
        #section-3-credencial,
        #credencial-div {
            padding: 0 10px;
        }
    }

    @media only screen and (max-width: 1000px) {
        .credencial-view {
            padding-top: 110px;
        }

        #certificado-ejemplo {
            max-width: 700px;
        }
    }
   
    @media only screen and (max-width: 900px) {
        .credencial-view #section-1 {
            margin: 0 auto 40px auto;
            padding: 40px 30px 0px 10px;
        }

        #validacion-texto-div {
            justify-content: flex-start;
            flex-direction: column;
            align-items: flex-start;
        }

        #credencial-div {
            padding-top: 0;
            padding-bottom: 0;
        }

        #credencial {
            margin-top: 20px;
        }

        #parrafo-div{
            margin-right: 0px;
            margin-left: 0px;
        }

    }

    @media only screen and (max-width: 700px) {
        #titulo-certificado p {
            font-size: 5vw;
            text-transform: uppercase;
        }
    }

    @media only screen and (max-width: 600px) {
        .credencial-view #section-1 {
            margin: 0 auto 40px auto;
            padding: 20px 30px 0px 10px;
        }

        #titulo-certificado p {
            font-size: 5vw;
            text-transform: uppercase;
        }

    }

    @media only screen and (max-width: 500px) {
        .parallax1 {
            width: 90%;
        }

        #section-1 {
            padding: 10px;
            margin-top: 10px;
        }
    }

    @media only screen and (max-width: 400px) {
        #titulo-certificado p {
            font-size: 6vw;
            text-transform: uppercase;
        }

        #dni-div input,
        #boton-verificar-div button {
            font-size: 12px;
        }

        #boton-verificar-div {
            margin-left: 0;
            margin-bottom: 10px;
        }
    }

    @media only screen and (max-width: 275px) {
        #credencial {
            flex-direction: column;
        }
    }

   </style>