<template>
    <nav class="nav">
        <div class="logo">
            <router-link to="/" class="wave-link" @click="refreshFooter" ><img data-info="/" id="logo-header" src="@/assets/images/logo-barbieria.png" aria-label="La Barbieria logo" alt=""></router-link>
        </div>
        <div id="mainListDiv" class="main_list">
            <div id="botones-credencial-certificado">
                <div >
                    <router-link  to="/credenciales" @click="refreshFooter" data-info="/credenciales">
                        <button class="raise">CREDENCIAL ALUMNO</button>
                    </router-link>
                </div>
                <div>
                    <router-link  to="/certificados" @click="refreshFooter" data-info="/certificados">
                        <button class="raise">VALIDAR CERTIFICADO</button>
                    </router-link>
                </div>
            </div>
            <ul class="navlinks">
                <li>
                    <router-link to="/" @click="refreshFooter" data-info="/">
                        INICIO                          
                    </router-link>
                </li>
                <li>
                    <router-link to="/academia"  @click="refreshFooter" data-info="/academia">
                        ACADEMIA                        
                    </router-link>
                </li>
                <li>
                    <router-link to="/franquicia"  @click="refreshFooter" data-info="/franquicia">
                        FRANQUICIA                        
                    </router-link>
                </li>
                <li>
                    <router-link to="/cursos"  @click="refreshFooter" data-info="/cursos">
                        CURSOS                           
                    </router-link>
                </li>
                <li>
                    <router-link to="/servicios" @click="refreshFooter" data-info="/servicios">
                        SERVICIOS                         
                    </router-link>
                </li>
                <li>
                    <router-link to="/contacto" @click="refreshFooter" data-info="/contacto">
                        CONTACTO                           
                    </router-link>
                </li>
                <li id="validar-a-tag">
                    <router-link to="/credenciales"  @click="refreshFooter" data-info="/credenciales">
                        CREDENCIAL ALUMNO  
                    </router-link>
                </li>
                <li id="validar-a-tag">
                    <router-link to="/certificados"  @click="refreshFooter" data-info="/certificados">
                        VALIDAR CERTIFICADO
                    </router-link>
                </li>
            </ul>
        </div>
        <span class="navTrigger" @click="trigger" :class="{active: isActive}">
            <i></i>
            <i></i>
            <i></i>
        </span>
    </nav>
</template>
   
<script>
export default {
     data() {
       return {
         isActive: false
       }
     },
     components: {
   
     },
    mounted() {
        addEventListener("scroll", (event) => {
            this.handleScroll()
        });
    },
    methods: {
        /* getResolution() {
            var w = window.innerWidth;
            if (parseInt(w) > 1000) {
                this.$store.dispatch('parallax')
            }
        }, */
        trigger() {
            this.isActive = !this.isActive;
            $("#mainListDiv").toggleClass("show_list");
            $("#mainListDiv").fadeIn('slow');
        },
        refreshFooter(e) {
            if (e.target.dataset.info != this.$route.path) {
                this.$store.state.footer = false;
            }
           
            var w = window.innerWidth;
            if (parseInt(w) <= 1000) {
                this.isActive = false
                $("#mainListDiv").removeClass("show_list");
            }
        },
        handleScroll() {
            var w = window.innerWidth;
            if ($(document).scrollTop() > 50) {
                $('.nav').addClass('bg-nav');
                $('.navTrigger').css('top', '45px');
                $('#logo-header').css('filter', 'invert(100%)');
                $('.navTrigger i').css('background-color', 'whitesmoke');
                $('#botones-credencial-certificado button').css('background-color', 'whitesmoke');
                $('#botones-credencial-certificado button').css('color', '#111111');
                if (parseInt(w) > 1000 ) {
                    $('#logo-header').css('width', '230px');
                    $('#logo-header').css('padding-top', '2px');
                }else {

                    $('#logo-header').css('width', '200px');
                }
            }else {
                $('#logo-header').css('filter', 'invert(0%)');
                $('#logo-header').css('padding-top', '0px');
                $('.navTrigger i').css('background-color', '#111111');
                $('.nav').removeClass('bg-nav');
                $('.navTrigger').css('top', '50px');
                $('#botones-credencial-certificado button').css('background-color', '#111111');
                $('#botones-credencial-certificado button').css('color', 'whitesmoke');
                if (parseInt(w) > 1250 ) {
                    $('#logo-header').css('width', '300px');
                }if (parseInt(w) < 1250 && parseInt(w) > 600) {
                    $('#logo-header').css('width', '240px');
                }if (parseInt(w) < 600) {
                    $('#logo-header').css('width', '220px');
                }
            }
        } 
    }
}
   
</script>
   
   <style lang="scss">

/* Navbar section */


.nav {
    width: 100%;
    height: 122px;
    position: fixed;
    //line-height: 65px;
    text-align: center;
    z-index: 999;
    padding-top: 20px;
    padding-bottom: 20px;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
}

.bg-nav {
    /*#3e7fb7, #40586c */
    padding-top: 20px;
    background-color: #0e0e0e;
}

.nav div.logo {
    float: left;
    width: auto;
    height: auto;
    padding-left: 3rem;
}

#logo-header {
    width: 300px;
    transition: 1s;
}

#botones-credencial-certificado {
    display: flex; 
    align-items: flex-end; 
    justify-content: flex-end;
}

#botones-credencial-certificado span {
 font-size: 12px;
 position: relative;
}

.nav div.main_list {
    height: 65px;
    float: right;
    margin-right: 40px;
}

.nav div.main_list ul {
    width: 100%;
    height: 65px;
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
}

.nav div.main_list ul li {
    width: auto;
    padding: 0 15px;

}

.navlinks li a {
    position: relative;
    padding: 0 15px;
    text-decoration: none;
    display: flex;
    justify-content: center;
    color: #ffffff;
    font-weight: bolder;
    line-height: 60px;
    transition: 1s;
}

#validar-a-tag {
    display: none;
}

#boton-validar,
#boton-credencial {
    color: #ffffff;
    width: 150px;
    display: flex;
    background-color: #111111;
    border: none;
    cursor: pointer;
    border-radius: 3px;
    line-height: 18px;
    transition: .3s ease-in; 
    text-shadow: none;
    padding: 5px 0 3px;
    margin: 0 15px;
}

#boton-validar span,
#boton-credencial span {
    font-size: 13px;
}

#boton-credencial {
    background-color: #444444;
}

#boton-validar:hover {
    transform: rotateX(360deg) !important;
}

#boton-credencial:hover {
    color: rgb(0, 0, 0) !important;
    background-color: whitesmoke;
}

.navlinks li a{
    font-size: 17px;
    text-shadow: 0px 0px 10px #111111;        
}

.navlinks li a:hover{
   color: rgb(88, 88, 88);    
}

.navTrigger {
    display: none;
    transition: all 0.4s ease;
}

// Size can also be negative; see how it's smaller than the element
.raise:hover {
  box-shadow: 0 20px 20px -0.9em rgb(83, 83, 83);
  transform: translateY(-0.25em);
}

// Basic button styles
#botones-credencial-certificado button {
  background: black;
  color: white;
  border: none;
  border-radius: 4px;
  line-height: 1;
  margin: 0 20px;
  padding: 5px 0 2px!important;
  width: 150px;
  font-size: 13px;
  line-height: 20px;
  transition: all .4s;
  font-weight: 600;
}

@media screen and (min-width: 1300px) and (max-width: 1385px) {
    .navlinks li a {
        font-size: 15px;
    }
}

@media screen and (max-width:1250px) {
    #logo-header {
        width: 240px;
    }
}

@media screen and (min-width: 1120px) and (max-width: 1320px) {
    .navlinks li a {
        font-size: 14px;
    }
    .nav div.main_list ul li {
        padding: 0px;
    }
}

@media screen and (min-width: 1080px) and (max-width: 1120px) {
    .navlinks li a {
        font-size: 13px;
    }
    .nav div.main_list ul li {
        padding: 0px;
    }
}

@media screen and (min-width: 1000px) and (max-width: 1080px) {
    .navlinks li a {
        font-size: 12px;
    }

    .nav div.main_list ul li {
       padding: 0px;
    }
}

@media screen and (max-width:1000px) {
    .navTrigger {
        display: block;
    }

    #botones-credencial-certificado {
        display: none !important;
    }

    .link__graphic {
        display: none;
    }

    .nav div.logo a img {
        display: inherit;
    }

    .nav div.logo {
        margin: auto;
        float: inherit;
        padding: 0;
    }
    
    .nav div.main_list {
        width: 100%;
        height: 0;
        overflow: hidden;
        margin-top: -113px;
        margin-right: 0;
    }

    .nav div.main_list .navlinks {
        padding-top: 115px;
        overflow: auto;
    }

    .nav div.show_list {
        height: auto;
        display: none;
    }

    .nav div.main_list ul {
        flex-direction: column;
        width: 100%;
        height: 105vh;
        right: 0;
        left: 0;
        bottom: 0;
        background-color: rgb(143, 143, 143);
        /*same background color of navbar*/
    }

    .nav div.main_list ul li {
        width: 100%;
        text-align: center;
        padding-right: inherit;
        height: 42px;
    }
    .nav div.main_list ul li a {
        text-align: center;
        width: 100%;
        font-size: 17px;
    }
    .nav div.media_button {
        display: block;
    }
    .nav div.main_list ul li a:hover {
        color: white;
    }

    #boton-validar {
        display: none;
    }

    #validar-a-tag {
        display: block;
    }
}

@media screen and (max-width:600px) {
    #logo-header {
        width: 220px;
    }
}


/* Animation */
/* Inspiration taken from Dicson https://codemyui.com/simple-hamburger-menu-x-mark-animation/ */

.navTrigger {
    cursor: pointer;
    width: 30px;
    height: 25px;
    margin: auto;
    position: absolute;
    top: 45px;
    left: 20px;
}

.navTrigger i {
    background-color: #000000;
    border-radius: 2px;
    content: '';
    display: block;
    width: 100%;
    height: 4px;
}

.navTrigger i:nth-child(1) {
    -webkit-animation: outT 0.8s backwards;
    animation: outT 0.8s backwards;
    -webkit-animation-direction: reverse;
    animation-direction: reverse;
}

.navTrigger i:nth-child(2) {
    margin: 5px 0;
    -webkit-animation: outM 0.8s backwards;
    animation: outM 0.8s backwards;
    -webkit-animation-direction: reverse;
    animation-direction: reverse;
}

.navTrigger i:nth-child(3) {
    -webkit-animation: outBtm 0.8s backwards;
    animation: outBtm 0.8s backwards;
    -webkit-animation-direction: reverse;
    animation-direction: reverse;
}

.navTrigger.active i:nth-child(1) {
    -webkit-animation: inT 0.8s forwards;
    animation: inT 0.8s forwards;
}

.navTrigger.active i:nth-child(2) {
    -webkit-animation: inM 0.8s forwards;
    animation: inM 0.8s forwards;
}

.navTrigger.active i:nth-child(3) {
    -webkit-animation: inBtm 0.8s forwards;
    animation: inBtm 0.8s forwards;
}


@-webkit-keyframes inM {
    50% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(45deg);
    }
}

@keyframes inM {
    50% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(45deg);
    }
}

@-webkit-keyframes outM {
    50% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(45deg);
    }
}

@keyframes outM {
    50% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(45deg);
    }
}

@-webkit-keyframes inT {
    0% {
        -webkit-transform: translateY(0px) rotate(0deg);
    }
    50% {
        -webkit-transform: translateY(9px) rotate(0deg);
    }
    100% {
        -webkit-transform: translateY(9px) rotate(135deg);
    }
}

@keyframes inT {
    0% {
        transform: translateY(0px) rotate(0deg);
    }
    50% {
        transform: translateY(9px) rotate(0deg);
    }
    100% {
        transform: translateY(9px) rotate(135deg);
    }
}

@-webkit-keyframes outT {
    0% {
        -webkit-transform: translateY(0px) rotate(0deg);
    }
    50% {
        -webkit-transform: translateY(9px) rotate(0deg);
    }
    100% {
        -webkit-transform: translateY(9px) rotate(135deg);
    }
}

@keyframes outT {
    0% {
        transform: translateY(0px) rotate(0deg);
    }
    50% {
        transform: translateY(9px) rotate(0deg);
    }
    100% {
        transform: translateY(9px) rotate(135deg);
    }
}

@-webkit-keyframes inBtm {
    0% {
        -webkit-transform: translateY(0px) rotate(0deg);
    }
    50% {
        -webkit-transform: translateY(-9px) rotate(0deg);
    }
    100% {
        -webkit-transform: translateY(-9px) rotate(135deg);
    }
}

@keyframes inBtm {
    0% {
        transform: translateY(0px) rotate(0deg);
    }
    50% {
        transform: translateY(-9px) rotate(0deg);
    }
    100% {
        transform: translateY(-9px) rotate(135deg);
    }
}

@-webkit-keyframes outBtm {
    0% {
        -webkit-transform: translateY(0px) rotate(0deg);
    }
    50% {
        -webkit-transform: translateY(-9px) rotate(0deg);
    }
    100% {
        -webkit-transform: translateY(-9px) rotate(135deg);
    }
}

@keyframes outBtm {
    0% {
        transform: translateY(0px) rotate(0deg);
    }
    50% {
        transform: translateY(-9px) rotate(0deg);
    }
    100% {
        transform: translateY(-9px) rotate(135deg);
    }
}

 
</style>
   
   
   