<template>
    <div class="is-loading-bar sound-wave" v-if="this.$store.state.isLoading">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
    </div>

    <CustomSelect 
        :options="localidades"
    />

    <transition name="fade2">
        <div v-if="showCursos">
            <div v-for="curso in cursos">
                <div id="curso-div" class="my-20 flex-col flex flex-wrap items-center justify-center lg:my-24 lg:flex-row mx-auto">
                    <div id="imagen-div" class="z-10 mb-8 lg:mb-0" @click="toggle(curso)">
                        <div class="box-curso">
                            <img :src='curso.get_imagen' alt='' >
                        </div>
                    </div>
                
                    <div id="curso" class="relative">
                        <div @click="toggle(curso)" id="titulo-curso" class="bg-black h-[100px] p-5 table w-[60vw] lg:ml-[-40px]"> 
                            <p class="mt-[27px] px-7 font-['Poppins'] align-middle cursor-pointer text-white lg:text-[25px] text-[15px] font-bold table-cell">
                                {{ curso.titulo_certificado }}
                            </p>
                            <span class="float-right mt-[27px] align-middle cursor-pointer text-white text-[25px] font-bold table-cell transition duration-300" :id="curso.id">
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="9" viewBox="0 0 14 9" fill="none">
                                    <path d="M1 1L7 7L13 1" stroke="#EBE8F0" stroke-width="2" stroke-linecap="round" />
                                </svg>
                            </span>
                        </div>
                        <transition name="fade2">
                        <div v-if="curso.isActive" id="descripcion-curso" class="lg:absolute relative text-black max-w-[60vw] bg-gradient-to-t from-black/[0.3] to-black/[0.0] lg:max-w-[55vw] max-h-[200px] mt-5 overflow-y-scroll">
                            <div v-for="item in curso.descripcion" class="p-5">
                                • {{ item }}
                            </div>
                            
                            <div>
                                <div class="text-center"> 
                                    <button type="button" @click="mandarWhatsApp([curso.telefono, curso.titulo_certificado])" class="text-white bg-black rounded py-2.5 px-5 text-[15px] mb-5 lg:hover:translate-y-[-0.25em] lg:hover:shadow-button transition duration-500">
                                        Saber mas!
                                    </button>
                                </div>
                            </div>
                        </div>
                        </transition>
                    </div>
                </div>
            </div>      
        </div>
    </transition>
    
</template>
   
<script>
import axios from 'axios'
import CustomSelect from '@/components/CustomSelectComp.vue'
 
export default {
    name: 'CursoComp',
    data() {
        return {
            cursos:[],
            localidades:[],
            localidad:'MAR DEL PLATA',
            showCursos:false
        }
    },
    mounted() {
        this.get_cursos()
        this.emitter.on("localidad", e => {
            this.showCursos = false
            if (e != this.localidad) {
                this.localidad = e
                this.get_cursos()
            }
            
        });
    },
    components: {
        CustomSelect
    },
    methods: {
        toggle(curso) {
            curso.isActive = !curso.isActive;
            const arrow = document.getElementById(curso.id);
            arrow.classList.toggle('rotate-180');
        },
        async get_cursos() {
            this.$store.commit('setIsLoading', true)
            await axios
                .get(`/api/v1/cursos/${this.localidad}`)
                .then(response => {
                    this.cursos = response.data['cursos']
                    this.localidades = response.data['ciudades']
                    this.showCursos = true
                })
                .catch(error => {
                    this.mensaje = 'Error al cargar los cursos!'
                    this.tipo = 'error'
                    this.toast =true
                    setTimeout(() => this.toast = false, 3000)
                })
                this.$store.commit('setIsLoading', false)
        },
        mandarWhatsApp (e) {
            let a= document.createElement('a');
            a.target= '_blank';
            a.href= "https://wa.me/"+e[0]+"/?text=Hola! me gustaria solicitar mas info del " + e[1] +", gracias!"
            a.click();
        },
    }
}
</script>

<style lang="scss">
#titulo-curso {
    box-shadow: var(--shadow2-1) 5px 5px, var(--shadow2-2) 10px 10px, var(--shadow2-3) 15px 15px, var(--shadow2-4) 20px 20px, var(--shadow2-5) 25px 25px;
}

.box-curso {
    width: 300px;
    height: 300px;
    position: relative;
    animation: spin 25s ease-in-out infinite alternate-reverse;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      border-radius: 50%;
      border: 2px dashed #111111;
      transform: scale(1.1);
    }
}

.box-curso img {
    width: 300px;
    height: 300px;
    border-radius: 50%;
    object-fit: cover;
}

.fade2-enter-active {
    transition: opacity 0.5s ease-in;
}
.fade2-leave-active {
    transition: opacity 1s ease;
}

.fade2-enter-from,
.fade2-leave-to {
    opacity: 0;
}

@media only screen and (max-width: 1000px) {
    .box-curso {
        width: 250px;
        height: 250px;
    }

    .box-curso img {
        width: 250px;
        height: 250px;
    }
}

select {
  /*webkit browsers */
  -webkit-appearance:initial;
  /*Firefox */
  -moz-appearance:initial;
  /* modern browsers */
  appearance:initial;
  border-radius: 0;

}
</style>