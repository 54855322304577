<template>
  <HeaderComp />

  <router-view v-slot="{ Component }">
    <transition name="scale" mode="out-in">
    <component :is="Component" :key="$route.path" />
    </transition>
  </router-view>

  <transition name="fade">
    <div class="request-loader" v-if="showWhatsapp">
      <span>
        <img src="@/assets/images/whatsapp2.png" width="70" height="70" alt="" @click="mandarWhatsApp">
      </span>
    </div>
  </transition>

  <transition name="fade">
    <div v-if="this.$store.state.footer">
      <FooterComp />
    </div>
  </transition>
</template>

<script>
  import HeaderComp from '@/components/HeaderComp';
  import FooterComp from '@/components/FooterComp';
  import axios from 'axios'

  export default {
    data() {
      return {
        showWhatsapp:false,
        showFooter:false
      }
    },
    beforeCreate() {
      this.$store.commit('initializeStore')
      const token = this.$store.state.token
      if (token) {
          axios.defaults.headers.common['Authorization'] = "Token " + token
      } else {
          axios.defaults.headers.common['Authorization'] = ""
      }
    },
    components: {
      HeaderComp,
      FooterComp,
    },
    mounted() {
      setTimeout(() => {
        this.showWhatsapp = true;
      }, 2000);
      setTimeout(() => {
        this.$store.state.footer = true;
      }, 3000);
    },
    methods: {
      mandarWhatsApp (e) {
        let a= document.createElement('a');
        a.target= '_blank';
        a.href= "https://wa.me/5492235748516/?text=Hola! me gustaria solicitar mas info de los cursos, gracias!"
        a.click();
      },
    }
  };
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Comfortaa&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@800&display=swap');

:root {
  --shadow-1: rgba(111, 0, 255, 0.4);
  --shadow-2: rgba(111, 0, 255, 0.3);
  --shadow-3: rgba(111, 0, 255, 0.2);
  --shadow-4: rgba(111, 0, 255, 0.1);
  --shadow-5: rgba(111, 0, 255, 0.05);

  --shadow2-1: rgba(0, 0, 0, 0.4);
  --shadow2-2: rgba(0, 0, 0, 0.3);
  --shadow2-3: rgba(0, 0, 0, 0.2);
  --shadow2-4: rgba(0, 0, 0, 0.1);
  --shadow2-5: rgba(0, 0, 0, 0.05);
}
 

html {
 background-color:rgb(224, 224, 224);
}

.grecaptcha-badge {
  visibility: hidden;
}

p, a, span{
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

*{
  font-size: 18px;
  letter-spacing: .1em;
  font-family: 'Comfortaa', cursive;
}

*:focus {
  -webkit-tap-highlight-color: transparent;
  outline: none;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

 /* INPUT BG AUTOCOMPLETE TRANSPARENT */

input:-webkit-autofill { 
  -webkit-background-clip: text;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  appearance: textfield;
  -moz-appearance: textfield;
}

.scale-enter-active,
.scale-leave-active {
  transition: all 0.4s ease;
}

.scale-enter-from,
.scale-leave-to {
  opacity: 0;
  transform: scale(0.95);
}

#turnos-div {
 position: fixed;
 right: 0;
 padding: 10px 20px 10px 10px;
 top: 83%;
 background-color: #6f00ff;
 color: whitesmoke;
}

#turnos-div p {
  font-family: 'Poppins', sans-serif;
}

@mixin rings($duration, $delay) {
    opacity: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    top:0;
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    height: 100%;
    width: 100%;
    border: 8px solid #4caf50;
    border-radius: 100%;
    animation-name: ripple;
    animation-duration: $duration;
    animation-delay: $delay;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(.65,0,.34,1);
    z-index: -1;
}

.request-loader {
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 70px;
  width: 70px;
  border-radius: 100%;
  box-shadow: 0 0 20px 0 rgba(0,0,0,0.25);
  top: 90%;
  right: 20px;
  z-index:999;
  
  span {
    width: 70px;
    top: 90%;
    right: 20px;
    z-index:999;
    position: fixed;
    cursor: pointer;
    transition: all .3s ease-in-out; 
  }
  
  &::after {
    @include rings(3s, 0s);
  }
  
  &::before {
    @include rings(3s, 0.5s);
  }
}

@keyframes ripple {
  from {
    opacity: 1;
    transform: scale3d(0.75,0.75,1);
  }
  
  to {
    opacity: 0;
    transform: scale3d(1.5,1.5,1);
  }
}

.request-loader span:hover {
  transform: scale(1.1);
}

.fade-enter-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

/* ===== SweetAlert ===== */

.swal2-popup {
  color: white;
  background: var(--bg-grey);
  background-position: center center;
  background-size: cover;
}

.swal2-icon-content{
  letter-spacing: 0;
}

.swal2-title {
  text-shadow: var(--text-shadow);
  letter-spacing: .1em;
  font-size: 33px;
  font-weight: 500;
}

.swal2-styled {
  margin: .3125em;
  padding: 0px 15px 0px 15px;
  transition: box-shadow .1s;
  box-shadow: 0 0 0 3px rgba(0,0,0,0);
  font-weight: 500;
}


/* ===== Loading wave ===== */

@keyframes audio-wave {
0%{
    height: 10px;
    trnsform: translateY(0px);
    background: #000000;
}
25%{
    height: 40px;
    trnsform: translateY(-5px);
    scaleY:(1.7);
    background:#979797;
}
50%{
    height: 10px;
    trnsform: translateY(0px);
    scaleY:(1.7);
    background: #414141;
}
100%{
    height: 10px;
    trnsform: translateY(0px);
    scaleY:(1.7);
    background: #b1b1b1;
}
}

.sound-wave{
  display:flex;
  justify-content: center;
  align-items:center;
  gap:8px;
  height:60px
}

.sound-wave span{
  height:18px;
  width:10px;
  display:block;
  border-radius:8px;
  background:rgb(90, 90, 90);
  animation:audio-wave 2.2s infinite ease-in-out
}

.sound-wave span:nth-child(2) {
  left:11px;
  background:rgb(122, 122, 122);
  animation-delay:0.2s
}

.sound-wave span:nth-child(3){
  left:22px;
  animation-delay:0.4s
}

.sound-wave span:nth-child(4){
  left:33px;
  animation-delay:0.6s
}
.sound-wave span:nth-child(5){
  left:44px;
  animation-delay:0.8s
}

.sound-wave span:nth-child(6){
  left:55px;
  animation-delay:1s
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #000000 #ffffff00;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 5px;
  background-color: #000000 #ffffff00;
}

*::-webkit-scrollbar-thumb {
  background-color: #555;
}

@media only screen and (max-width: 1210px) {
  .request-loader, .request-loader span {
    top: 80%;
  }
}

@media only screen and (max-width: 1000px) {
  .request-loader span:hover {
    transform: scale(1);
  }
}

.swiper-horizontal>.swiper-pagination-bullets.swiper-pagination-bullets-dynamic 
.swiper-pagination-bullet, .swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic 
.swiper-pagination-bullet {
    background-color: rgb(0, 0, 0);
}

</style>
