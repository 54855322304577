<template>
	<h1 id="localidad-titulo">SELECCIONA LOCALIDAD</h1>
	<div class="custom-select" tabindex="0">
		<div class="selected" @click=toggle()>
			<span id="option-select" class="text-white">{{ selected }}</span>
			<span class="icon transition duration-300" id="localidad-select">
				<svg xmlns="http://www.w3.org/2000/svg" width="14" height="9" viewBox="0 0 14 9" fill="none">
					<path d="M1 1L7 7L13 1" stroke="#EBE8F0" stroke-width="2" stroke-linecap="round" />
				</svg>
			</span>
		</div>
		<transition name="fade2">
			<div class="items" v-if="open" :class="{ selectHide: !open }">
				<div v-for="option of options" :key="option" id="options" @click="selectOption(option)">
					<span>{{ option }}</span>
				</div>
			</div>
		</transition>
	</div>
</template>
  
<script>
export default {
	props: {
		options: {
			type: Array,
			required: true,
		}
	},
	data() {
		return {
			selected: 'MAR DEL PLATA',
			optionSelected: '',
			open: false,
		};
	},
	mounted() {
	},
	methods: {
		toggle() {
            this.open = !this.open;
			const arrow = document.getElementById('localidad-select');
            arrow.classList.toggle('rotate-180');
        },
		selectOption(e) {
			this.toggle()
			this.optionSelected = this.selected
			if (e.name) {
				this.selected = e.name;
			} else {
				this.selected = e;
			}
			this.open = false;
			//let text = document.querySelectorAll("[id='option-select']")
			if (this.selected != this.optionSelected) {
				this.emitter.emit('localidad', this.selected)
			}
		}
	}
};
</script>
  
<style scoped>
#localidad-titulo {
	margin: 10px auto;
	font-family: 'Poppins', sans-serif;
}
.custom-select {
	position: relative;
	text-align: left;
	outline: none;
	height: 47px;
	line-height: 47px;
	width: 230px;
	margin: auto;
	z-index: 99;
	background-color: black;
	color: white;
	box-shadow: var(--shadow2-1) 5px 5px, var(--shadow2-2) 10px 10px, var(--shadow2-3) 15px 15px, var(--shadow2-4) 20px 20px, var(--shadow2-5) 25px 25px;
}

.custom-select .selected {
	/* border-radius: 4px; */
	color: #a8a8a8;
	padding-left: 10px;
	cursor: pointer;
	user-select: none;
	width: 230px;
	height: 42px;
	overflow: hidden;
}

#option-select {
	font-family: 'Poppins', sans-serif;
	position: relative;
}

.selected .selected:after {
	display: none;
}
.icon {
	position: absolute;
	right: 10px;
	top: 20px;
}
.custom-select .items {
	overflow: hidden;
	position: absolute;
	left: 0;
	right: 0;
	z-index: 1;
	max-height: 150px;
	overflow-y: scroll;
	width: 230px;
	margin-top: 20px;
	background-color: black;;
}

.custom-select .items div {
	padding-left: 1em;
	cursor: pointer;
	user-select: none;
	font-size: 12px;
}

#options span {
	font-family: 'Poppins', sans-serif;
	color: rgb(255, 255, 255);
}

#options:hover {
	background-color: rgb(90, 90, 90);
}

.selectHide {
	display: none;
}

@media only screen and (max-width: 1000px) {
	#localidad-titulo {
		margin-top: 30px;
	}
}
</style>
  