import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'
import HomeView from '../views/HomeView.vue'
import AcademiaView from '../views/AcademiaView.vue'
import CursosView from '../views/CursosView.vue'
import ServiciosView from '../views/ServiciosView.vue'
import ContactoView from '../views/ContactoView.vue'
import CertificadoView from '../views/CertificadoView.vue'
import CredencialView from '../views/CredencialView.vue'
import FranquiciaView from '../views/FranquiciaView.vue'


const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/academia',
    name: 'academia',
    component: AcademiaView
  },
  {
    path: '/franquicia',
    name: 'franquicia',
    component: FranquiciaView
  },
  {
    path: '/cursos/:localidad*',
    name: 'cursos',
    component: CursosView
  },
  {
    path: '/servicios',
    name: 'servicios',
    component: ServiciosView
  },
  {
    path: '/contacto',
    name: 'contacto',
    component: ContactoView
  },
  {
    path: '/certificados',
    name: 'certificados',
    component: CertificadoView
  },
  {
    path: '/credenciales',
    name: 'credenciales',
    component: CredencialView
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: "/"
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior: function (to, _from, savedPosition) {
    
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { el: to.hash, behavior: "smooth" };
    } else {

    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 5)
  }
 }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requireLogin) && !store.state.isAuthenticated) {
    next({ name: 'home'});
  } else {
    next()
  }
})

export default router
