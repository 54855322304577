<template>
    <div id="toast-div-success" class="shadow-xl" v-if="tipo == 'success'">
        <p>{{ mensaje }}</p>
    </div>

    <div id="toast-div-error" class="shadow-xl" v-else>
        <p>{{ mensaje }}</p>
    </div>
</template>
   
<script>

 
export default {
    name: 'ToastComp',
    props: ['mensaje', 'tipo'],
    data() {
        return {
        
        }
    },
    mounted() {
    
    },
    components: {

    },
    methods: {

    }
}
</script>
   
   
<style>
 
    #toast-div-success {
        max-width: 300px;
        background-color: rgb(76 175 80);
        position: fixed;
        top: 15%;
        right: 10px;
        color: white;
    }

    #toast-div-error {
        max-width: 300px;
        background-color: rgb(158, 8, 8);
        position: fixed;
        top: 15%;
        right: 10px;
        color: black;
    }

    #toast-div-success p,
    #toast-div-error p {
        padding: 10px;
    }
   
    @media screen and (min-width: 690px) and (max-width: 1050px) {

    }

    @media only screen and (max-width: 690px) {

    }
   
   </style>